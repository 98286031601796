import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import authRoutes from "@/router/modules/auth";
import dashboardRoutes from "@/router/modules/dashboard";
import websitePolicyRoutes from "@/router/modules/websitePolicy";

const routes = [];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  linkActiveClass: "",
  scrollBehavior: () => ({ top: 0 }),
  routes: routes.concat(authRoutes, dashboardRoutes, websitePolicyRoutes),
});

router.beforeEach(async (to, from, next) => {
  const titlePage = to.meta.label || "";
  document.title = `EDTP | ${titlePage}  ǀ  leki on-line ǀ zamawiaj 24/7 ǀ hurtownia farmaceutyczna`;
  document.head.querySelector(
    "meta[name=description]"
  ).content = `Zaopatrzenie aptek - leki, suplementy diety i wyroby medyczne - zamawiaj 24/7.`;
  let isAuthenticated = await store.getters["auth/isAuthenticated"];
  if (to.matched.some((record) => record.meta.authorized)) {
    if (!isAuthenticated) {
      await store.dispatch("auth/fetchCurrentUser").then((res) => {
        isAuthenticated = !!res.data.user;
      });
      next({ name: "auth.login" });
      return;
    }
  }
  if (to.matched.some((record) => record.meta.guest)) {
    if (isAuthenticated) {
      next({ name: "dashboard" });
      return;
    }
  }
  next();
});

export default router;
