import { useStore } from "vuex";
import { computed } from "vue";
import { find, filter } from "lodash-es";

export function selectProductsUtils() {
  const store = useStore();

  const markedProducts = computed(() => {
    return [...store.getters["products/markedProducts"]];
  });

  const markedProductsLength = computed(() => markedProducts.value.length);

  const isMarked = (id) => {
    const item = find(markedProducts.value, ["product", id]);
    return item !== undefined;
  };

  const isMarkedProduct = (id) => {
    const products = filter(markedProducts.value, ["mode", "product"]);
    const item = find(products, ["product", id]);
    return item !== undefined;
  };

  const isMarkedPackage = (id) => {
    const packages = filter(markedProducts.value, ["mode", "package"]);
    const item = find(packages, ["product", id]);
    return item !== undefined;
  };

  const onMarkAll = (products) => {
    if (markedProducts.value.length !== products.length) {
      setMarkedProducts([...products]);
    } else {
      setMarkedProducts([]);
    }
  };

  const removeObjectWithId = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.product === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  };

  const onMarkProduct = (payload) => {
    let markedProductsState = markedProducts.value;
    const item = find(markedProductsState, ["product", payload.product]);
    if (item === undefined) {
      markedProductsState.push(payload);
    } else {
      markedProductsState = removeObjectWithId(
        markedProductsState,
        payload.product
      );
    }
    setMarkedProducts(markedProductsState);
  };

  const setMarkedProducts = (payload) => {
    store.dispatch("products/onMarkedProducts", payload);
  };

  return {
    isMarked,
    markedProducts,
    markedProductsLength,
    onMarkProduct,
    onMarkAll,
    setMarkedProducts,
    isMarkedPackage,
    isMarkedProduct,
  };
}
