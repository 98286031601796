import i18n from "@/i18n";
import api from "@/store/axiosPlugin";
import { gtmUtils } from "@/modules/utils/gtmUtils";
import { find } from "lodash";

const { sendToGTM } = gtmUtils();

const store = {
  namespaced: true,

  state: {
    countItemInCart: 0,
    cart: {
      priceNet: 0,
      basicPriceNet: 0,
      priceGross: 0,
      cartWarehouses: [],
      narcoticDocuments: [],
      productSuggestionsByProduct: {
        products: [],
      },
    },
    errors: [],
    quantityOfPackagesIncreased: false,
  },

  mutations: {
    setCart(state, payload) {
      state.cart = payload;
      state.errors = payload.errors;
      let count = 0;
      payload.cartWarehouses.map((item) => {
        count =
          item.coldPackages.length +
          item.warmPackages.length +
          item.coldProducts.length +
          item.warmProducts.length +
          count;
      });
      state.countItemInCart = count;
      if (
        payload.productSuggestionsByProduct !== null &&
        "products" in payload.productSuggestionsByProduct
      ) {
        state.cart.productSuggestionsByProduct.products =
          payload.productSuggestionsByProduct.products;
      } else {
        state.cart.productSuggestionsByProduct = { products: [] };
      }
    },

    setQuantityOfPackagesIncreased(state, payload) {
      state.quantityOfPackagesIncreased = payload;
    },
  },

  actions: {
    async getCart(context) {
      const res = await api.get(`/cart/`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      context.commit("setCart", res.data);

      return res.data;
    },

    async addProductToCart(context, { id, count, shelf }) {
      const res = await api.get(`/cart/add-to-cart/${id}/${count}/${shelf}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      const cartWarehouses = res.data.cartWarehouses;

      if (id === 142) {
        let prolia = cartWarehouses.map((item) => {
          return find(item.coldProducts, ["productId", id]);
        });

        let product = {
          item_name: prolia[0].productName,
          item_id: id,
          quantity: count,
          price: Number(Number(prolia[0].productPriceNet / 100).toFixed(2)),
        };

        sendToGTM({
          event: "AddToCartProlia",
          ecommerce: {
            items: product,
            currency: "PLN",
            value:
              count *
              Number(
                Number(productSendToCart[0].productPriceNet / 100).toFixed(2)
              ),
          },
        });
      }

      let productSendToCart = cartWarehouses.map((item) => {
        let p = find(item.warmProducts, ["productId", Number(id)]);
        if (p === undefined) {
          return find(item.coldProducts, ["productId", Number(id)]);
        } else {
          return p;
        }
      });

      if (productSendToCart[0]) {
        let product = {
          item_name: productSendToCart[0].productName,
          item_id: id,
          quantity: count,
          price: Number(
            Number(productSendToCart[0].productPriceNet / 100).toFixed(2)
          ),
        };

        sendToGTM({
          event: "add_to_cart",
          ecommerce: {
            items: [product],
            currency: "PLN",
            value:
              count *
              Number(
                Number(productSendToCart[0].productPriceNet / 100).toFixed(2)
              ),
          },
        });
      }

      context.commit(
        "setQuantityOfPackagesIncreased",
        res.data.quantityOfPackagesIncreased
      );
      context.commit("setCart", res.data);
    },

    async removeAllFromCart(context) {
      const res = await api.get(`/cart/remove-all-products-from-cart`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      context.commit("setCart", res.data);
    },

    async removeProductItemFromCart(context, { id, name, price, count }) {
      const res = await api.get(`/cart/remove-all-product-from-cart/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      let product = {
        item_name: name,
        item_id: id,
        price: Number(Number(price / 100).toFixed(2)),
        quantity: count,
      };

      sendToGTM({
        event: "remove_from_cart",
        items: [product],
      });
      context.commit("setCart", res.data);
    },

    async removePackageItemFromCart(context, { id }) {
      const res = await api.get(`/cart/remove-all-packages-from-cart/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      context.commit("setCart", res.data);
    },

    async changeProductCountInCart(context, { id, count }) {
      const res = await api.get(
        `/cart/set-product-count-in-cart/${id}/${count}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      context.commit("setCart", res.data);
    },

    async changePackageCountInCart(context, { id, count }) {
      const res = await api.get(
        `/cart/set-package-count-in-cart/${id}/${count}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      context.commit("setCart", res.data);
    },

    async sendOrder(context) {
      try {
        const res = await api.get(`/cart/cart-finalize`);

        if (!res.data) {
          new Error(i18n.t("Error occured while processing your request."));
        }
        const cart = res.data;

        let tagEcommerceInformation = {
          transactionId: cart.orderId.toString(),
          transactionTotal: Number(Number(cart.priceNet / 100).toFixed(2)),
          transactionAffiliation: "",
          transactionCurrency: "PLN",
          transactionShipping: 0,
          transactionTax: 0,
          transactionProducts: [],
        };

        let tagEcommerceInformationPurchase = {
          transaction_id: cart.orderId.toString(),
          value: Number(Number(cart.priceNet / 100).toFixed(2)),
          tax: 0,
          shipping: 0,
          currency: "PLN",
          items: [],
        };

        cart.orderLines.map((item) => {
          tagEcommerceInformation.transactionProducts.push({
            item_id: item.relatedProductId,
            item_name: item.label,
            sku: item.sku,
            price: Number(Number(item.priceNet / 100).toFixed(2)),
            quantity: item.count,
          });

          tagEcommerceInformationPurchase.items.push({
            item_id: item.relatedProductId,
            item_name: item.label,
            sku: item.sku,
            price: Number(Number(item.priceNet / 100).toFixed(2)),
            quantity: item.count,
          });
        });

        sendToGTM({
          event: "Pageview2",
          tagEcommerceInformation,
        });

        sendToGTM({
          event: "purchase",
          tagEcommerceInformationPurchase,
        });

        return Promise.resolve(res.data);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          return Promise.reject(error);
        } else {
          return Promise.reject(
            new Error("Error occurred while processing your request.")
          );
        }
      }
    },

    async addSeveralProductsToCart({ commit }, payload) {
      const res = await api.post(`/cart/add-products-list`, payload);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async attachNarcoticDocument({ commit }, payload) {
      const res = await api.post(`/cart/narcotic-document/add`, payload);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async removeNarcoticDocument({ commit }, id) {
      const res = await api.post(`/cart/narcotic-document/delete/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setCart", res.data);
    },
  },

  getters: {
    countItemInCart: (state) => state.countItemInCart,
    cart: (state) => state.cart,
    errors: (state) => state.errors,
    quantityOfPackagesIncreased: (state) => state.quantityOfPackagesIncreased,
  },
};

export default store;
