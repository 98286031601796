<template>
  <Button
    text="button.Download-requisition-formula"
    variant="outline-primary"
    id="narcoticProduct__buttonDownloadRequisitionFormula"
    @click="onShowModal"
  />
  <Modal :visible="visible" class="narcoticProductModal">
    <template #header>
      <span></span>
      <Button variant="link" type="button" class="btn-close" @click="onShowModal" id="narcoticProductModal__buttonClose" />
    </template>
    <template #body>
      <div class="text-center narcoticProductModal__body">
        <div class="narcoticProductModal__name">
          {{ $t("The product requires a scan", { name: product.name }) }}
        </div>
        <FileInput
          :name="documentName"
          :required="true"
          @add="addDocument"
          @remove="removeDocument"
          :documents="formValues"
        />
      </div>
    </template>
    <template #footer>
      <a :href="narcoticForm" target="_blank" download="Wzór_zapotrzebowania">
        <Button
          text="button.Download-form-template"
          variant="outline-primary"
          id="narcoticProductModal__buttonDownloadFormTemplate"
        />
      </a>
      <Button text="button.Add-form" variant="primary" @click="onSubmit" id="narcoticProductModal__buttonAddForm"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal";
import { modalUtils } from "@/modules/utils/modalUtils";
import Button from "@/modules/components/base/Button";
import FileInput from "@/modules/components/base/FileInput";
import narcoticFileTemplate from "@/assets/files/wzor_zaptrzebowania_narkotyk.pdf";
import { ref } from "vue";
import { mapKeys } from "lodash-es";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { cartUtils } from "@/modules/utils/cartUtils";

export default {
  name: "NarcoticProductModal",

  components: { FileInput, Button, Modal },

  props: {
    product: Object,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    let { visible, productInModal, onShowModal, showModal } = modalUtils();
    let { queryCart } = cartUtils();
    const formValues = ref({});
    const narcoticForm = ref(narcoticFileTemplate);
    const documentName = ref(
      `${t("Demand pattern")} ${t("Annex to the regulation")}`
    );

    const onSubmit = async () => {
      let payload = {};
      if (formValues.value[documentName.value]) {
        if (Object.values(formValues.value[documentName.value]).length > 0) {
          payload = Object.values(formValues.value[documentName.value])[0];
        }
      }
      await store.dispatch("order/attachNarcoticDocument", payload).then(() => {
        queryCart();
        onShowModal();
      });
    };

    const addDocument = ({ value, name }) => {
      mapKeys(value.target.files, (file) => {
        if (file.size !== 0) {
          const reader = new FileReader();
          const documentName = file.name;

          reader.onload = (e) => {
            let files = { ...formValues.value[name] };
            files[file.name] = {
              fileContent: e.target.result,
              fileName: documentName,
            };
            formValues.value = { ...formValues.value, [name]: files };
          };
          reader.readAsDataURL(file);
        }
      });
    };

    const removeDocument = (name) => {
      const newDocumentObject = { ...formValues.value };
      delete newDocumentObject[name];
      formValues.value = newDocumentObject;
    };

    return {
      visible,
      productInModal,
      onShowModal,
      showModal,
      formValues,
      narcoticForm,
      documentName,
      addDocument,
      removeDocument,
      onSubmit,
    };
  },
};
</script>

<style scoped></style>
