<template>
  <div v-if="withCounter" class="counter">
    <b-input-group>
      <template #prepend>
        <b-button
          :disabled="itemValue <= 1"
          class="counter__decrease"
          @click="onReduce"
        >
          <b-icon-dash />
        </b-button>
      </template>
      <b-form-input
        v-model.number="itemValue"
        type="number"
        :min="1"
        @input="onChangeValueByInput($event)"
      ></b-form-input>
      <template #append>
        <b-button class="counter__increase" @click="onAdd">
          <b-icon-plus />
        </b-button>
      </template>
    </b-input-group>
  </div>
  <Button
    v-if="withButton === true"
    id="counter__buttonAdd"
    v-b-tooltip.hover
    :text="text"
    :icon="icon"
    class="mx-3 counter__button"
    variant="primary"
    :title="$t('button.Add-to-cart')"
    @click="emitValueAddToCart"
  >
    <template v-if="icon"><b-icon-bag /></template>
  </Button>
</template>

<script>
import Button from "@/modules/components/base/Button";
import { counterUtils } from "@/modules/utils/counterUtils";
import { computed } from "vue";

export default {
  name: "Counter",

  components: { Button },

  props: {
    count: {
      type: Number,
      default: 1,
    },
    withButton: {
      type: Boolean,
      default: false,
    },

    withCounter: {
      type: Boolean,
      default: true,
    },

    isInCart: {
      type: Boolean,
    },

    withModal: {
      type: Boolean,
      default: false,
    },

    text: {
      type: String,
      default: "button.Add",
    },

    icon: {
      type: Boolean,
      default: true,
    },

    product: {
      type: Object
    },
  },

  emits: ["changeValue", "click"],

  setup(props, { emit }) {
    const { itemValue, onReduce, onChangeValueByInput, onAdd, emitValue } =
      counterUtils(props, emit);

    const productsData = computed(() => {
      return props.product;
    });

    const emitValueAddToCart = () => {
      emitValue(productsData.value);
    };

    return {
      itemValue,
      onReduce,
      onChangeValueByInput,
      onAdd,
      emitValueAddToCart,
    };
  },
};
</script>

<style scoped></style>
