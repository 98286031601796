import i18n from "@/i18n";
import api from "@/store/axiosPlugin";

const store = {
  namespaced: true,

  state: {
    promotions: [],
    promotionSearch: [],
  },

  getters: {
    promotions: (state) => state.promotions,
    promotionSearch: (state) => state.promotionSearch
  },

  mutations: {
    setPromotions(state, payload) {
      state.promotions = payload;
    },
    setPromotionsSearch(state, payload) {
      state.promotionSearch = payload;
    },
  },

  actions: {
    async getPromotions({ commit }, payload) {
      const res = await api.post(`/pharmacy-package/filter`, payload);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      commit("setPromotions", res.data);
    },

    async getPromotionsSearch({ commit }, payload) {
      const res = await api.post(`/pharmacy-package/filter`, payload);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      commit("setPromotionsSearch", res.data);
    },

    async getPromotionToEdit({ commit }, id) {
      const res = await api.get(`/pharmacy-package/edit/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    async editPromotion({ commit }, { packageType, payload }) {
      const res = await api.get(
        `/pharmacy-package/edit/${packageType}`,
        payload
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    async createPromotion({ commit }, { id, payload }) {
      const res = await api.post(
        `/pharmacy-package/create/${id}`,
        payload
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    async getPromotion({ commit }, id) {
      const res = await api.get(`/pharmacy-package/get/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    async calculatePackage({ commit }, payload) {
      const res = await api.post(
        `/pharmacy-package/calculate/${payload.pharmacyPackageId}`,
        payload
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },
  },
};

export default store;
