<template>
  <section class="fileInput" :key="name">
    <div class="bold text-center">
      {{ name }} <span v-if="required === true" class="text-danger">*</span>
    </div>
    <div class="fileInput__wrapper">
      <div class="fileWrapper">
        <input
          multiple
          type="file"
          :required="required"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          @change="addDocument($event)"
        />
        <div :class="`${documents[name] ? 'active' : null}`">
          <b-icon-cloud-arrow-up class="shake" v-if="documents[name]" />
          <b-icon-cloud-arrow-up v-else />

          <p :class="`${documents[name] ? 'opacity' : null}`">
            {{ $t("Click the icon to add or drag a document") }}
          </p>
        </div>
        <p v-if="documents[name]" class="addedDocument">
          {{ $t("Remove added documents") }}
          {{ Object.keys(documents[name]).length }}
          <b-icon-trash @click="removeDocument" />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FileInput",

  props: {
    name: String,
    documents: Object,
    required: Boolean,
  },

  emits: ["remove", "add"],

  setup(props, { emit }) {
    const removeDocument = () => {
      emit("remove", props.name);
    };

    const addDocument = (e) => {
      emit("add", { value: e, name: props.name });
    };
    return {
      removeDocument,
      addDocument,
    };
  },
};
</script>

<style scoped></style>
