const DashboardView = () =>
  import("@/modules/views/Dashboard/DashboardView");
const CompanyDetails = () =>
  import("@/modules/views/WebsitePolicy/CompanyDetails");
const WebsiteRegulations = () =>
  import("@/modules/views/WebsitePolicy/WebsiteRegulations");
const PrivacyPolicy = () =>
  import("@/modules/views/WebsitePolicy/PrivacyPolicy");
const PersonalDataProtection = () =>
  import("@/modules/views/WebsitePolicy/PersonalDataProtection");
const Contact = () => import("@/modules/views/WebsitePolicy/Contact");

const routes = [
  {
    path: "/regulamin",
    redirect: "/regulations",
    name: "regulations",
    component: DashboardView,

    meta: {
      freeAccess: true,
      authorized: false,
      label: "",
    },

    children: [
      {
        path: "/company-details",
        name: "companyDetails",
        component: CompanyDetails,

        meta: {
          label: "Dane firmy",
        },
      },

      {
        path: "/website-regulations",
        name: "websiteRegulations",
        component: WebsiteRegulations,

        meta: {
          label: "Regulamin",
        },
      },

      {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: PrivacyPolicy,

        meta: {
          label: "Polityka prywatności",
        },
      },

      {
        path: "/personal-data-protection",
        name: "personalDataProtection",
        component: PersonalDataProtection,

        meta: {
          label: "Ochrona danych osobowych",
        },
      },

      {
        path: "/formularzKontaktowy",
        name: "contact",
        component: Contact,

        meta: {
          label: "Kontakt",
        },
      },
    ],
  },
];

export default routes;
