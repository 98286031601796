<template>
  <table id="tableComponent" class="table table-hover table-responsive-md">
    <thead>
      <tr>
        <th
          v-for="field in fields"
          :key="field.key"
          :class="field.classTh || ''"
        >
          <div v-if="field.scopedSlotTh">
            {{ field.title }}
            <b-icon-chevron-up
              v-if="form[field.scopedSlotTh.customRender] === 'asc'"
              @click="onSort(field.scopedSlotTh.customRender, 'desc')"
            />
            <b-icon-chevron-down
              v-else
              @click="onSort(field.scopedSlotTh.customRender, 'asc')"
            />
          </div>
          <div v-else v-html="field.title" />
        </th>
      </tr>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
    <tfoot>
      <slot name="footer"></slot>
    </tfoot>
  </table>
</template>

<script>
import { tableFilters } from "@/modules/utils/table-filters";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "Table",

  mixins: [tableFilters],

  props: {
    fields: {
      type: Array,
    },
  },

  emits: ["sort"],

  setup(props, { emit }) {
    const store = useStore();

    const form = computed(() => {
      return { ...store.getters["ui/sort"] };
    });

    const onSort = (key, value) => {
      store.dispatch("ui/updateSort", { key: key, value: value });
      emit("sort");
    };
    return {
      form,
      onSort,
    };
  },
};
</script>

<style scoped></style>
