<template>
  <div class="productWithBadge" @click.self="redirectToProduct">
    <div class="productWithBadge__wrapper">
      <ProductImageWrapper
        :is-hit="hitBadge"
        :is-new="newBadge"
        :img-path="image || imagePath || productDefaultImgSm"
        :product-type="productType || product_type || {}"
        :is-cooler-required="isCoolerRequired || false"
        :is-promotion="isInPharmacyPackage || isInPromotion || false"
        @click="redirectToProduct"
      />
      <div class="productWithBadge__main" @click.self="redirectToProduct">
        <div class="productWithBadge__productName" @click="redirectToProduct">
          {{ name || productName }}
        </div>
        <div
          v-if="withPrice"
          class="productWithBadge__priceWrapper"
          @click.self="redirectToProduct"
        >
          <ProductPrice
            :price-net="priceNet"
            :price-gross="priceGross"
            :with-format="withFormat"
            @click="redirectToProduct"
          />
          <Counter
            :key="`count${id}`"
            :with-button="true"
            :with-counter="false"
            :is-in-cart="false"
            text=""
            :icon="true"
            :with-modal="true"
            :product="product"
            :count="1"
            @changeValue="onChangeValue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductPrice from "@/modules/components/Products/ProductPrice";
import { useRouter } from "vue-router";
import ProductImageWrapper from "@/modules/components/Products/ProductItem/ProductImageWrapper";
import Counter from "@/modules/components/base/Counter";
import { productUtils } from "@/modules/utils/productUtils";
import { reactive, toRefs, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProductWithBadge",

  components: { Counter, ProductImageWrapper, ProductPrice },

  props: {
    productData: Object,
    newBadge: Boolean,
    hitBadge: Boolean,
    withPrice: { type: Boolean, default: true },
    withFormat: { type: Boolean, default: true }
  },

  async setup(props) {
    const router = useRouter();
    const store = useStore();
    let { productDefaultImgSm } = productUtils();
    const product = reactive(props.productData);

    const {
      name,
      imagePath,
      priceNet,
      priceGross,
      id,
      image,
      productName,
      manufacturerName,
      isCoolerRequired,
      isPrescriptionRequired,
      isInPromotion,
      form,
      productType,
      isInStock,
      shelfUsage,
      warehouse,
      isNarcotic,
      bloz,
      isInPharmacyPackage,
      product_type,
    } = toRefs(product);

    const redirectToProduct = () => {
      router.push({
        name: "products.product",
        params: { productId: id.value },
      });
    };

    const onChangeValue = (count) => {
      store.dispatch("ui/loader", true);
      const shelfUsage = "1";
      store
        .dispatch("order/addProductToCart", {
          id: id.value,
          count: count,
          shelf: shelfUsage,
        })
        .finally(() => {
          store.dispatch("ui/loader", false);
        });
    };

    await new Promise((res) => setTimeout(res, 2000));

    return {
      productDefaultImgSm,
      router,
      onChangeValue,
      redirectToProduct,
      product,
      name,
      imagePath,
      priceNet,
      priceGross,
      id,
      image,
      product_type,
      productName,
      manufacturerName,
      isCoolerRequired,
      isPrescriptionRequired,
      isInPromotion,
      form,
      productType,
      isInStock,
      shelfUsage,
      warehouse,
      isNarcotic,
      bloz,
      isInPharmacyPackage,
    };
  },
};
</script>

<style scoped></style>
