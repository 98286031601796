import { reactive } from "vue";
const state = reactive({
  type: "alert",
  active: false,
  icon: "",
  message: "",
  title: "",
  okText: "Ok",
  okVariant: "primary",
  cancelText: "Cancel",
  cancelVariant: "outline-primary",
  inputType: "text",
  html: false,
});

let close;
const dialogPromise = () => new Promise((resolve) => (close = resolve));
const open = (message) => {
  state.message = message;
  state.active = true;
  return dialogPromise();
};
const reset = () => {
  state.active = false;
  state.icon = "";
  state.message = "";
  state.okVariant = "primary";
  state.okText = "Ok";
  state.cancelText = "Cancel";
  state.cancelVariant = "outline-primary";
  state.inputType = "text";
  state.html = false;
  state.title = "";
  state.type = "alert";
};

const dialog = {
  get state() {
    return state;
  },
  icon(icon) {
    state.icon = icon;
    return this;
  },
  title(title) {
    state.title = title;
    return this;
  },
  okText(text) {
    state.okText = text;
    return this;
  },

  okVariant(text) {
    state.okVariant = text;
    return this;
  },

  cancelVariant(text) {
    state.cancelVariant = text;
    return this;
  },

  cancelText(text) {
    state.cancelText = text;
    return this;
  },
  inputType(type) {
    state.inputType = type;
    return this;
  },
  html(enabled = true) {
    state.html = enabled;
    return this;
  },
  alert(message) {
    state.type = "alert";
    return open(message);
  },
  confirm(message) {
    state.type = "confirm";
    return open(message);
  },
  prompt(message) {
    state.type = "prompt";
    return open(message);
  },
  cancel() {
    close(false);
    reset();
  },
  ok(input = true) {
    input = state.type === "prompt" ? input : true;
    close(input);
    reset();
  },
};

export default dialog;
