export function promotionUtils() {
  const changeType = (type) => {
    switch (type) {
      case "Pakiet groszówkowy":
        return "TYPE_COIN_BASED";
      case "Pakiet ilościowy":
        return "TYPE_COUNT_BASED";
      case "Pakiet wartościowy":
        return "TYPE_AMOUNT_BASED";
    }
  };
  return {
    changeType,
  };
}
