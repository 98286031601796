<template>
  <Modal
    :visible="visible"
    id="personalizedPopupForm"
    class="personalizedPopups"
  >
    <template #header>
      <div class="personalizedPopups__header">
        <Button variant="link" type="button" class="btn-close" @click="onHide" id="personalizedPopupForm__buttonClose" />
      </div>
    </template>
    <template #body>
      <b-form @submit="onSubmit" class="personalizedPopups__mainSection">
        <div class="personalizedPopups__productSection">
          <div>
            <img
              :src="product.image || productDefaultImg"
              :alt="product.name"
              :width="245"
              :height="130"
            />
          </div>
          <div class="personalizedPopups__form">
            <div class="personalizedPopups__text8 text-center">
              W trosce o najlepszą obsługę, <br />
              chcielibyśmy się dowiedzieć <br />
              dlaczego nie zdecydowałeś/aś <br />
              się na zakup produktu
              <span class="personalizedPopups__text9">{{ product.name }}</span
              >?
            </div>
            <div>
              <b-form-radio-group
                :options="radioOptions"
                v-model="checkOption"
              />
            </div>
          </div>
        </div>
        <button
          :class="`personalizedPopups__button buttonSubmit ${
            checkOption === false ? 'disabled' : ''
          }`"
          type="submit"
          id="personalizedPopupsForm__buttonSubmit"
        >
          Wyślij opinię
        </button>
      </b-form>
    </template>
    <template #footer> </template>
  </Modal>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Modal from "@/modules/components/base/Modal";
import { productUtils } from "@/modules/utils/productUtils";
import Button from "@/modules/components/base/Button";

export default {
  name: "PersonalizedPopupForm",

  components: { Button, Modal },

  setup() {
    const store = useStore();
    const checkOption = ref(false);
    const { productDefaultImg } = productUtils();
    const radioOptions = ref([
      { text: "posiadam zapas tego produktu", value: "a" },
      { text: "cena na eDTP jest za wysoka", value: "b" },
    ]);

    const visible = computed(() => {
      return store.getters["extras/showPersonalizedPopupId"] === 3;
    });

    const product = computed(() => store.getters["extras/popupProduct"]);

    const onSubmit = () => {
      store.dispatch("ui/loader", true);
      store
        .dispatch("extras/sendRespondToPersonalizedProductForm", {
          product: product.value.id,
          option: checkOption.value,
        })
        .then(() => {
          store.dispatch("extras/showPersonalizedPopup", 4);
        })
        .finally(() => {
          store.dispatch("ui/loader", false);
        });
    };

    const onHide = () => {
      store.dispatch("extras/showPersonalizedPopup", 0);
    };

    return {
      visible,
      checkOption,
      product,
      onHide,
      onSubmit,
      productDefaultImg,
      radioOptions,
    };
  },
};
</script>

<style scoped></style>
