<template>
  <Table :fields="fields">
    <tr v-for="(item, index) in orderLines" :key="index">
      <td
        v-for="field in fields"
        :key="field.key"
        :width="field.width"
        :class="field.classTd || ''"
      >
        <template v-if="field.scopedSlots">
          <div
            v-if="field.scopedSlots.customRender === 'product'"
            class="table__line--two"
          >
            <div class="app__color--primary">{{ item.label }}</div>
            <span>{{ item.manufacturerName }}</span>
          </div>
          <template v-if="field.scopedSlots.customRender === 'count'">
            {{ item.count }} szt.
          </template>
          <template v-if="field.scopedSlots.customRender === 'invoiceQuantity'">
            {{ item.invoiceQuantity }} szt.
          </template>
          <template v-if="field.scopedSlots.customRender === 'priceSumNet'">
            {{ $n(formatPrice(item.priceSumNet)) }} zł
          </template>
          <template v-if="field.scopedSlots.customRender === 'priceSumGross'">
            {{ $n(formatPrice(item.priceSumGross)) }} zł
          </template>
          <template v-if="field.scopedSlots.customRender === 'vatRate'">
            {{ item.vatRate }}%
          </template>
        </template>

        <template v-else>{{ item[field.key] }}</template>
      </td>
    </tr>
  </Table>
</template>

<script>
import Table from "@/modules/components/base/Table";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatPrice } from "@/modules/utils/format-price";

export default {
  name: "OrderDetailsModalTable",

  components: { Table },

  props: {
    orderLines: Array,
  },

  setup() {
    const { t } = useI18n();
    const fields = computed(() => [
      {
        key: "productId",
        title: t("form.Product"),
        scopedSlots: { customRender: "product" },
      },
      {
        key: "count",
        title: t("form.Ordered"),
        scopedSlots: { customRender: "count" },
        classTd: "text-align-center",
        classTh: "text-align-center",
      },
      {
        key: "invoiceQuantity",
        title: t("form.Realized"),
        scopedSlots: { customRender: "invoiceQuantity" },
        classTd: "text-align-center",
        classTh: "text-align-center",
      },
      {
        key: "priceSumNet",
        title: t("form.Price net"),
        scopedSlots: { customRender: "priceSumNet" },
        classTd: "text-align-center",
        classTh: "text-align-center",
      },
      {
        key: "priceSumGross",
        title: t("form.Price-gross"),
        scopedSlots: { customRender: "priceSumGross" },
        classTd: "text-align-center",
        classTh: "text-align-center",
      },
      {
        key: "vatRate",
        title: t("form.VAT"),
        scopedSlots: { customRender: "vatRate" },
        classTd: "text-align-center",
        classTh: "text-align-center",
      },
    ]);
    return {
      fields,
    };
  },

  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
  },
};
</script>

<style scoped></style>