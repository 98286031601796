<template>
  <div class="loader" v-show="loader === true">
    <div class="spinner"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Loader",

  setup() {
    const store = useStore();
    const loader = computed(() => store.getters["ui/loader"]);

    return {
      loader,
    };
  },
};
</script>

<style scoped></style>
