import { debounce } from "lodash-es";

export const tableFilters = {
  props: {
    filters: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    form() {
      return { ...this.$store.getters["ui/filters"] };
    },
  },
  methods: {
    applyFilters: debounce(function () {
      this.$store.dispatch("ui/updateFilters", this.form);
      this.$emit("onApplyFilters");
    }, 1000),
  },
};
