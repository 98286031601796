import { ref } from "vue";

export function modalUtils() {
  const visible = ref(false);
  const productInModal = ref({});

  const onShowModal = () => {
    visible.value = !visible.value;
  };

  const showModal = (showProduct) => {
    productInModal.value = showProduct;
    onShowModal();
  };
  return {
    visible,
    productInModal,
    onShowModal,
    showModal,
  };
}
