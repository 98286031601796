<template>
  <Modal :visible="visible" id="orderDetailsModal">
    <template #header>
      <div>
        <h4>{{ $t("views.Details-of-order") }}</h4>
        <div>
          {{ $t("Order number") }}
          <span class="bold" v-if="order !== {}">{{ order.orderId }}</span>
        </div>
      </div>
      <Button
        variant="link"
        type="button"
        class="btn-close"
        @click="onShowModal"
        id="orderDetailsModal__buttonClose"
      />
    </template>
    <template #body>
      <ProgressBar :steps="steps" :current="current" :withLabel="false" />
      <div
        class="app__border--green d-flex justify-content-between align-items-center px-3 py-3 mb-4 orderDetailsModal"
      >
        <div>
          <div class="mb-3 historyOfOrders__details">
            <span class="bold">{{ $t("form.Status") }}:</span>
            <StatusBadge :status-value="order.orderStatus" />
          </div>
          <div class="historyOfOrders__details">
            <span class="bold">{{ $t("form.Date-of-order") }}:</span>
            {{ format(order.creationDate) }}
          </div>
        </div>

        <div>
          <div class="mb-3 historyOfOrders__details">
            <span class="bold">{{ $t("form.Order-amount") }}:</span>
            {{ $n(formatPrice(order.priceGross)) }} {{ $t("zl-gross") }}
          </div>
          <div class="text-end">
            <Button
              text="button.Retry"
              variant="secondary"
              :icon="true"
              :id="`orderDetailsModal__buttonRetry${order.orderId}`"
              @click="retryOrder"
            >
              <b-icon-arrow-clockwise />
            </Button>
            <Button
              text="button.Cancel"
              variant="danger"
              :icon="true"
              :disabled="order.orderStatus !== 1"
              :id="`orderDetailsModal__buttonCancel${order.orderId}`"
              @click="cancelOrder"
            >
              <b-icon-trash />
            </Button>
          </div>
        </div>
      </div>
      <OrderDetailsModalTable :order-lines="order.orderLines" />
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { formatPrice } from "@/modules/utils/format-price";
import Button from "@/modules/components/base/Button";
import { formatDate } from "@/modules/utils/format-date";
import ProgressBar from "@/modules/components/base/ProgressBar";
import OrderDetailsModalTable from "@/modules/components/Account/OrderDetailsModalTable";
import StatusBadge from "@/modules/components/Account/StatusBadge";
import dialog from "@/modules/utils/dialogUtils";
import { useI18n } from "vue-i18n";

export default {
  name: "OrderDetailsModal",

  components: {
    StatusBadge,
    OrderDetailsModalTable,
    ProgressBar,
    Button,
    Modal,
  },

  setup() {
    const errors = ref("");
    const store = useStore();
    const order = ref({});
    const steps = ref(["0", "1", "2", "3"]);
    const current = ref(0);
    const { t } = useI18n();

    const orderId = computed(() => store.getters["account/orderId"]);
    const visible = computed(() => store.getters["account/showOrderDetail"]);

    const queryOrder = async () => {
      await store.dispatch("ui/loader", true);
      const result = await store.dispatch("account/getOrder", orderId.value);
      populateOrder(result);
    };

    const populateOrder = (result) => {
      order.value = result;
      setCurrent(result.orderStatus);
      store.dispatch("ui/loader", false);
    };

    const setCurrent = (status) => {
      switch (status) {
        case 1:
        case 15:
          current.value = 0;
          break;
        case 2:
          current.value = 1;
          break;
        case 4:
          current.value = 2;
          break;
        case 25:
          current.value = 3;
          break;
        default:
          current.value = 4;
          break;
      }
    };

    const cancelOrder = async () => {
      onShowModal();
      let confirmed = await dialog
        .okText(t("button.Cancel-order"))
        .okVariant("danger")
        .cancelText(t("button.Return"))
        .confirm(t("Are you sure you want to cancel the order"));
      if (confirmed) {
        await store.dispatch("ui/loader", true);
        await store
          .dispatch("account/cancelOrder", orderId.value)
          .then((res) => {
            errors.value = res.message;
            queryOrder();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            store.dispatch("ui/loader", false);
          });
      }
    };

    const retryOrder = () => {
      store.dispatch("account/onShowOrderDetail", orderId.value);
      store.dispatch("account/onShowOrderRetry", orderId.value);
    };

    const onShowModal = () => {
      store.dispatch("account/onShowOrderDetail", 0);
    };

    watch(
      () => visible.value,
      (newValue) => {
        if (newValue === true) {
          queryOrder();
        }
      }
    );

    return {
      order,
      orderId,
      visible,
      steps,
      current,
      onShowModal,
      cancelOrder,
      retryOrder,
    };
  },

  methods: {
    format(date) {
      return formatDate(date);
    },
    formatPrice(price) {
      return formatPrice(price);
    },
  },
};
</script>

<style scoped></style>
