<template>
  <div class="progressBar">
    <ul class="progressBar__wrapper">
      <li :class="progressBarClass(i)" v-for="i in steps" :key="i">
        <span v-if="withLabel === true">{{ $t(i) }}</span>
        <span v-else></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",

  props: {
    steps: {
      type: Array,
    },
    current: {
      type: Number,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const progressBarClass = (item) => {
      if (props.current === props.steps.indexOf(item)) {
        return "active";
      } else if (props.current > props.steps.indexOf(item)) {
        return "previous";
      } else {
        return "next";
      }
    };
    return {
      progressBarClass,
    };
  },
};
</script>
