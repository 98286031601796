<template>
  <div class="notification">
    <div v-if="!userOnlineStatusLocalStorage">
      {{ $t("No internet connection") }}
    </div>
    <div v-if="user !== null && user.is_sales_allowed === false && open === true">
      {{ $t("We have blocked the ability to order medicines on your account") }} <b-icon-x-lg @click="handleClick" />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "Notification",

  setup() {
    const store = useStore();
    const open = ref(true);
    const userOnlineStatusLocalStorage = ref(true);
    let intervalId;

    const user = computed(() => store.getters["auth/status"]);

    onMounted(() => {
      intervalId = setInterval(() => {
        if (navigator.onLine) {
          if (!userOnlineStatusLocalStorage.value) {
            location.reload();
          }
        } else {
          userOnlineStatusLocalStorage.value = false;
        }
      }, 5000);
    });

    onUnmounted(() => clearInterval(intervalId));

    const handleClick = () => {
      open.value = !open.value;
    };

    return {
      handleClick,
      userOnlineStatusLocalStorage,
      user,
      open,
    };
  },
};
</script>

<style scoped></style>
