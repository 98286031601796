import { useStore } from "vuex";
import { computed } from "vue";

export function cartUtils() {
  const store = useStore();
  const queryCart = async () => {
    await store.dispatch("ui/loader", true);
    await store.dispatch("order/getCart").finally(() => {
      store.dispatch("ui/loader", false);
    });
  };

  const cart = computed(() => store.getters["order/cart"]);
  const countItemInCart = computed(
    () => store.getters["order/countItemInCart"]
  );

  const errors = computed(() => store.getters["order/errors"]);

  const calculateLogisticMinimum = (logisticMinimumPriceNett, sumNett) => {
    return Number(logisticMinimumPriceNett - sumNett / 100) <= 0;
  };

  return {
    store,
    queryCart,
    cart,
    countItemInCart,
    errors,
    calculateLogisticMinimum,
  };
}
