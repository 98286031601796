<template>
  <Modal id="stadaPopup" :visible="visible" class="stadaPopup" @close="onHide">
    <template #header> </template>
    <template #body>
      <Button
        id="stadaPopup__buttonClose"
        type="button"
        class="btn-close"
        variant="link"
        @click="onHide"
      />
      <img
        ref="bannerImage"
        :src="popup"
        alt="Banner"
        usemap="#image-map"
        class="banner-image"
        @load="updateCoords"
      />
      <map name="image-map">
        <area
          shape="rect"
          :coords="coords"
          href="#"
          alt="Button1"
          @click="handleButtonClick"
        />
      </map>
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal.vue";
import { useStore } from "vuex";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import stadaPopup from "@/assets/img/stada_popup.png";
import { useRouter } from "vue-router";
import Button from "@/modules/components/base/Button.vue";

export default {
  name: "StadaPopup",

  components: { Button, Modal },

  setup() {
    const store = useStore();
    const router = useRouter();
    const popup = ref(stadaPopup);
    const coords = ref("");
    const bannerImage = ref(null);

    const visible = computed(() => {
      return store.getters["extras/showStadaPopup"] === true;
    });

    const updateCoords = () => {
      if (bannerImage.value) {
        const image = bannerImage.value;
        const originalWidth = 760;
        const originalHeight = 395;

        const scaleX = image.clientWidth / originalWidth;
        const scaleY = image.clientHeight / originalHeight;

        const x1 = 47 * scaleX;
        const y1 = 306 * scaleY;
        const x2 = 287 * scaleX;
        const y2 = 359 * scaleY;

        coords.value = `${x1},${y1},${x2},${y2}`;
      }
    };

    const handleButtonClick = (event) => {
      event.preventDefault();
      router.push({
        name: "promotions.promotion",
        params: { pharmacyPackageId: 35, type: "TYPE_AMOUNT_BASED" },
      });
      onHide();
    };
    const onHide = () => {
      store.dispatch("extras/showStadaPopup", false);
    };

    onMounted(() => {
      window.addEventListener("resize", updateCoords);
      updateCoords();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateCoords);
    });

    return {
      bannerImage,
      visible,
      onHide,
      popup,
      handleButtonClick,
      updateCoords,
      coords,
    };
  },
};
</script>

<style scoped></style>
