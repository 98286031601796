const LetYourselfBeFound = () =>
  import("@/modules/views/MarketingCampaigns/LetYourselfBeFound");

const routes = [
  {
    path: "/dajsieznalezc",
    name: "extras.letYourselfBeFound",
    component: LetYourselfBeFound,

    meta: {
      label: "#DAJSIEZNALEZC",
    },
  },
];

export default routes;
