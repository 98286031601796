import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";

export function formatDate(date) {
  if (date === "*" || date === null) {
    return "-";
  } else {
    return dayjs(date).format(dateFormat);
  }
}
