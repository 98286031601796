const Dashboard = () => import("@/modules/views/Dashboard/DashboardView");
const DashboardLayout = () =>
  import("@/modules/components/Dashboard/Dashboard/DashboardLayout");

import productsRoutes from "./products";
import orderRoutes from "./order";
import accountRoutes from "./account";
import promotionsRoutes from "./promotions";
import extrasRoutes from "./extras";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "home",
    component: Dashboard,

    meta: {
      freeAccess: false,
      authorized: true,
      label: "Strona główna",
    },

    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardLayout,
      },
      {
        path: "dashboard/search",
        name: "dashboardSearch",
        component: DashboardLayout,
      },
      {
        path: "dashboard/categories",
        name: "dashboardCategories",
        component: DashboardLayout,
      },
      {
        path: "dashboard/manufacturers",
        name: "dashboardManufacturers",
        component: DashboardLayout,
      },
      {
        path: "dashboard/promotions",
        name: "dashboardPromotions",
        component: DashboardLayout,
      },
    ],
  },
];

routes[0].children = routes[0].children.concat(
  productsRoutes,
  orderRoutes,
  accountRoutes,
  promotionsRoutes,
  extrasRoutes
);

export default routes;
