<template>
  <div v-if="dialog.state.active" class="dialog">
    <div class="dialog__inner">
      <div class="dialog__wrapper">
        <div class="dialog__titleWrapper">
          <div class="dialog__icon">
            <img
              :src="dialog.state.icon === 'alert' ? alert : ''"
              alt="alertIcon"
              v-if="dialog.state.icon"
              :width="60"
              :height="60"
            />
          </div>
          <div :class="`dialog__title ${dialog.state.icon === 'alert' ? 'text-danger' : ''}`">
            <div v-if="dialog.state.title">{{ dialog.state.title }}</div>
          </div>
        </div>
        <div class="dialog__bodyWrapper">
          <p v-if="dialog.state.html" v-html="dialog.state.message"></p>
          <p v-else>{{ dialog.state.message }}</p>

          <input
            v-if="dialog.state.type === 'prompt'"
            :type="dialog.state.inputType"
            v-model="userInput"
          />
        </div>

        <div class="dialog__buttonWrapper">
          <Button
            v-if="dialog.state.type !== 'alert'"
            @click="dialog.cancel()"
            :icon="true"
            :variant="dialog.state.cancelVariant"
            id="dialog__buttonCancel"
          >
            {{ dialog.state.cancelText }}
          </Button>
          <Button
            @click="dialog.ok(userInput)"
            :icon="true"
            :variant="dialog.state.okVariant"
            id="dialog__buttonOk"
          >
            {{ dialog.state.okText }}
          </Button>
        </div>
      </div>
    </div>
    <div class="dialog-bg" @click="dialog.cancel()"></div>
  </div>
</template>

<script>
import dialog from "@/modules/utils/dialogUtils";
import Button from "@/modules/components/base/Button";
import alert from "@/assets/img/alert_icon.png";

export default {
  components: { Button },
  data() {
    return {
      userInput: "",
      dialog,
      alert,
    };
  },
};
</script>

<style scoped></style>
