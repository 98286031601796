import api from "@/store/axiosPlugin";
import i18n from "@/i18n";
import { gtmUtils } from "@/modules/utils/gtmUtils";

const { sendToGTM } = gtmUtils();

const onSendToGTM = (id) => {
  let popup = {
    id: id,
  };

  let products = [];
  store.state.popupProducts.map((product) => {
    products.push(product.id);
  });

  sendToGTM({
    event: "PopupShow",
    sendData: {
      popup: popup,
      products: products,
    },
  });
};

const store = {
  namespaced: true,

  state: {
    bannersList: [],
    bannerProducts: [],
    isQuizEnabled: false,
    showPersonalizedPopupId: 0,
    showStadaPopup: false,
    popupProducts: [],
    popupProduct: {},
  },

  mutations: {
    setBannersList(state, payload) {
      state.bannersList = payload;
    },

    setBannerWithProducts(state, payload) {
      state.bannerProducts = Object.values(payload);
      let banners = state.bannersList;
      if (payload.length > 0) {
        banners.push({ little_image: "", small_img: "", links: [], name: "" });
      }
      state.bannersList = banners;
    },

    setQuizEnabled(state, payload) {
      state.isQuizEnabled = payload.enabled !== 0;
    },

    setShowPersonalizedPopupId(state, payload) {
      state.showPersonalizedPopupId = payload;
    },

    setStadaEnabled(state, payload) {
      state.showStadaPopup = payload;
    },

    setPopupProducts(state, payload) {
      state.popupProducts = payload;
    },

    setPopupProduct(state, payload) {
      state.popupProduct = payload;
    },
  },

  actions: {
    async getLowPricesAreaBanners({ commit }) {
      const res = await api.get(`/extras/custom-banner/list/low-prices-area`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setBannersList", res.data);
    },

    async getProductsAreaBanners({ commit }) {
      const res = await api.get(`/extras/custom-banner/list/products-area`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setBannersList", res.data);
    },

    async getBannersWithProducts({ commit }) {
      const res = await api.get(`/extras/product-page-banner`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setBannerWithProducts", res.data);
    },

    async getMainPageProducts({ commit }) {
      const res = await api.get(`/extras/main-page-products`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getFavoriteProductsBanner({ commit }) {
      const res = await api.get(
        `/extras/custom-banner/list/favorite-products-area`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setBannersList", res.data);
    },

    async getCustomBanners({ commit }) {
      const res = await api.get(`/extras/custom-banner/list/default`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getSuggestProductsForId({ commit }, { id, option }) {
      const res = await api.get(
        `/extras/suggest-something-for-product/${option}/${id}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getSuggestProducts({ commit }, { option }) {
      const res = await api.get(`/extras/suggest-something/${option}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getIsQuizEnabled({ commit }) {
      const res = await api.get(`/extras/quiz-configuration`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setQuizEnabled", res.data);
    },

    async getPersonalizedPopupsProducts({ commit }) {
      const res = await api.get(`/extras/pharmacy_data_popup`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setPopupProducts", res.data);
      return res.data;
    },

    async sendRespondToPersonalizedProductForm(
      { commit },
      { product, option }
    ) {
      const res = await api.get(
        `/extras/pharmacy_data_popup/save_result/${product}/${option}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    showPersonalizedPopup({ commit }, payload) {
      if (payload !== 0) {
        switch (payload) {
          case 1:
            onSendToGTM("personalizedPopupsLogIn");
            break;
          case 2:
            onSendToGTM("personalizedPopupsReorder");
            break;
          case 3:
            onSendToGTM("personalizedPopupsNotAdd");
            break;
          case 4:
            onSendToGTM("personalizedPopupsThanks");
            break;
        }
      }
      commit("setShowPersonalizedPopupId", payload);
    },

    showStadaPopup({ commit }, payload) {
      commit("setStadaEnabled", payload);
    },

    addProductPopup({ commit }, payload) {
      commit("setPopupProduct", payload);
    },

    async getLetYourselfBeFoundValues({ commit }) {
      const res = await api.get(`/user/letyourselfbefound`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getLetYourselfBeFound({ commit }, payload) {
      const res = await api.post(`/user/letyourselfbefound`, payload);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      sendToGTM({
        event: "SendDajSieZnalezc",
        sendData: {
          data: payload,
        },
      });

      return res.data;
    },
  },

  getters: {
    bannersList: (state) => state.bannersList,
    bannerProducts: (state) => state.bannerProducts,
    isQuizEnabled: (state) => state.isQuizEnabled,
    showPersonalizedPopupId: (state) => state.showPersonalizedPopupId,
    showStadaPopup: (state) => state.showStadaPopup,
    popupProducts: (state) => state.popupProducts,
    popupProduct: (state) => state.popupProduct,
  },
};

export default store;
