<template>
  <div
    :id="`productCard-${id}`"
    :class="`productCard ${isInStock === false ? 'disabled' : ''} ${
      suggestedProducts ? 'lightBackground' : ''
    }`"
  >
    <div class="productCard__wrapper">
      <div v-if="withCheck === true">
        <b-form-checkbox
          :value="id"
          :checked="isMarked(id)"
          @change="onMarkProduct({ count: count, product: id })"
        />
      </div>
      <div class="product__imgWrapper">
        <img
          :src="imagePath || productDefaultImg"
          :alt="name"
          class="productCard__productImg"
          :height="90"
          :width="144"
        />
      </div>
      <div class="productCard__productWrapper">
        <div class="text-start">
          <router-link
            :to="{ name: 'products.product', params: { productId: id } }"
          >
            <div class="productCard__header">{{ name }}</div>
          </router-link>
          <div class="productCard__twoLine">
            {{ manufacturerName }}
            <span>{{ $t("form.Bloz") }}: {{ bloz }}</span>
          </div>

          <ProductTypeIcons
            :product-type="productType || product_type"
            :is-cooler-required="isCoolerRequired"
            :is-in-pharmacy-promotion="isInPharmacyPackage"
            :is-narcotic="isNarcotic"
            :is-prescription-required="isPrescriptionRequired"
            :product="product"
          />
        </div>

        <div class="text-end">
          <ProductPrice
            :price-net="priceNet"
            :price-gross="priceGross"
            :with-format="withFormat"
          />
          <div v-if="isInStock === true">
            <div
              v-if="showCount === false"
              class="d-flex justify-content-end align-items-center"
            >
              <Counter
                :key="`count${id}`"
                :count="count"
                :with-button="true"
                :is-in-cart="isInCart"
                :with-modal="true"
                :product="product"
                @changeValue="onChangeValue"
              />
              <FavoriteIcon
                :is-favorite="favoriteProduct"
                :product-id="id"
                @query="queryProducts"
              />
            </div>
            <div
              v-if="isInStock === true && showCount === true"
              class="d-flex justify-content-between align-items-center"
            >
              {{ product.count }} {{ piecesName(product.count) }}
              <FavoriteIcon
                :is-favorite="favoriteProduct"
                :product-id="id"
                @query="queryProducts"
              />
            </div>
          </div>
          <div v-else>{{ $t("Product unavailable") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import Counter from "@/modules/components/base/Counter";
import FavoriteIcon from "@/modules/components/base/FavoriteIcon";
import ProductTypeIcons from "@/modules/components/base/ProductTypeIcons";
import { productUtils } from "@/modules/utils/productUtils";
import { useRoute } from "vue-router";
import ProductPrice from "@/modules/components/Products/ProductPrice";
import { selectProductsUtils } from "@/modules/utils/selectProductsUtils";

export default {
  name: "ProductCard",

  components: {
    ProductPrice,
    ProductTypeIcons,
    FavoriteIcon,
    Counter,
  },

  props: {
    product: Object,
    showCount: {
      type: Boolean,
      default: false,
    },
    suggestedProducts: {
      type: Boolean,
      default: false,
    },
    withCheck: {
      type: Boolean,
      default: false,
    },
    withFormat: {
      type: Boolean,
    },
  },

  emits: ["queryProducts", "isInMarkedProducts", "markProduct"],

  async setup(props, { emit }) {
    const route = useRoute();
    const productData = reactive(props.product);

    let { onMarkProduct, isMarked } = selectProductsUtils();

    let {
      store,
      productDefaultImg,
      isInCart,
      narcoticStamp,
      count,
      checkCount,
    } = productUtils();

    const {
      name,
      imagePath,
      priceNet,
      priceGross,
      id,
      manufacturerName,
      isCoolerRequired,
      isPrescriptionRequired,
      isInPromotion,
      form,
      productType,
      isInStock,
      warehouse,
      isNarcotic,
      bloz,
      isInPharmacyPackage,
      product_type,
    } = toRefs(productData);

    const favoriteProduct = computed(() => {
      return props.product.is_favorite;
    });

    const queryProducts = () => {
      emit("queryProducts");
    };

    const onChangeValue = (countValue) => {
      store.dispatch("ui/loader", true);
      const shelfUsage = "1";
      store
        .dispatch("order/addProductToCart", {
          id: id.value,
          count: countValue,
          shelf: shelfUsage,
        })
        .then(() => {
          queryProducts();
          checkCount(id.value);
        })
        .finally(() => {
          store.dispatch("ui/loader", false);
        });
    };

    const piecesName = (count) => {
      if (count === 1) {
        return "sztuka";
      } else if (count >= 2 && count <= 4) {
        return "sztuki";
      } else {
        return "sztuk";
      }
    };

    await new Promise((res) => setTimeout(res, 2000));

    return {
      name,
      imagePath,
      priceNet,
      priceGross,
      id,
      manufacturerName,
      isCoolerRequired,
      isPrescriptionRequired,
      isInPromotion,
      form,
      productType,
      isInStock,
      warehouse,
      isNarcotic,
      bloz,
      favoriteProduct,
      isInPharmacyPackage,
      onChangeValue,
      queryProducts,
      productDefaultImg,
      count,
      isInCart,
      narcoticStamp,
      product_type,
      route,
      onMarkProduct,
      isMarked,
      piecesName,
    };
  },
};
</script>

<style scoped></style>
