<template>
  <div class="productTypeIcons">
    <img
      :src="productType.iconPath"
      :alt="productType.name.slice(0, 3)"
      v-b-tooltip.hover
      :title="productType.name"
      v-if="productType.iconPath"
      :width="30"
      :height="30"
    />
    <img
      :src="prescriptionIcon"
      alt="prescriptionIcon"
      v-if="isPrescriptionRequired === true"
      :width="30"
      :height="30"
    />
    <img
      :src="promotionIcon"
      alt="isPromotion"
      v-if="isPromotion === true"
      :width="30"
      :height="30"
    />
    <img
      :src="coldIcon"
      alt="isCoolerRequired"
      v-b-tooltip.hover
      :title="$t('Cold-transport')"
      v-if="isCoolerRequired === true"
      :width="30"
      :height="30"
    />
    <Button
      text="button.buy-cheaper-in-package"
      variant="outline-danger"
      :id="`productTypeIcons__buttonBuyCheaperInPackage${product.id}`"
      @click="
        router.push({
          name: 'dashboardSearch',
          query: { product: product.id, name: product.name },
        })
      "
      v-if="isInPharmacyPromotion === true"
    />
    <template v-if="product !== {} && isNarcotic">
      <NarcoticProductModal :product="product" v-if="isNarcotic" />
    </template>
  </div>
</template>

<script>
import cold from "@/assets/img/coldIcon.png";
import prescriptionAdd from "@/assets/img/dodaj-recepte-100.png";
import promo from "@/assets/img/promo-50.png";
import { ref } from "vue";
import Button from "@/modules/components/base/Button";
import NarcoticProductModal from "@/modules/components/base/NarcoticProductModal";
import { useRouter } from "vue-router";

export default {
  name: "ProductTypeIcons",
  components: { NarcoticProductModal, Button },
  props: {
    productType: { type: Object, default: {} },
    isInPharmacyPromotion: { type: Boolean, default: false },
    isPrescriptionRequired: { type: Boolean, default: false },
    isNarcotic: { type: Boolean, default: false },
    isCoolerRequired: { type: Boolean, default: false },
    isPromotion: { type: Boolean, default: false },
    product: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const router = useRouter();
    const coldIcon = ref(cold);
    const prescriptionIcon = ref(prescriptionAdd);
    const promotionIcon = ref(promo);

    return {
      router,
      coldIcon,
      prescriptionIcon,
      promotionIcon,
    };
  },
};
</script>

<style scoped></style>