<template>
  <td
    v-for="field in fields"
    :key="field.key"
    :width="field.width"
    :class="field.classTd || ''"
  >
    <template v-if="field.scopedSlots">
      <div
        v-if="field.scopedSlots.customRender === 'product'"
        class="table__line--two"
      >
        <div class="text-primary">
          <router-link
            :to="{
              name: 'products.product',
              params: { productId: item.product.id },
            }"
          >
            {{ item.product.name }}
          </router-link>
        </div>
        <span>{{ item.product.manufacturer.name }}</span>
      </div>
      <template v-if="field.scopedSlots.customRender === 'count'">
        <Counter :count="item.count" @changeValue="onChangeValue" />
      </template>
      <template v-if="field.scopedSlots.customRender === 'priceSumNet'">
        {{ $n(formatPrice(countValue * item.product.priceNet)) }} zł
      </template>
      <template v-if="field.scopedSlots.customRender === 'priceSumGross'">
        {{ $n(formatPrice(countValue * item.product.priceGross)) }} zł
      </template>
    </template>
    <template v-else>{{ item[field.key] }}</template>
  </td>
</template>

<script>
import Counter from "@/modules/components/base/Counter";
import { formatPrice } from "@/modules/utils/format-price";
import { debounce } from "lodash-es";
import { ref } from "vue";

export default {
  name: "OrderRetryModalTableLine",

  components: { Counter },

  props: {
    fields: Object,
    item: Object,
  },

  emits: ["change"],

  setup(props, { emit }) {
    const countValue = ref(props.item.count);
    const onChangeValue = debounce((count) => {
      countValue.value = count;
      emit("change", { product: props.item.product.id, count: count });
    }, 1000);

    return {
      onChangeValue,
      countValue,
    };
  },

  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
  },
};
</script>

<style scoped></style>