const Promotions = () => import("@/modules/views/Promotions/Promotions");
const Promotion = () => import("@/modules/views/Promotions/Promotion");

const routes = [
  {
    path: "/promocje",
    redirect: "/dashboard?value=promotions",
    name: "promotions",
    component: Promotions,

    meta: {
      label: "Pakiety",
    },

    children: [
      {
        path: ":pharmacyPackageId/:type",
        name: "promotions.promotion",
        component: Promotion,
      },

      {
        path: ":promotionId/:pharmacyPackageId/:type/edit",
        name: "promotions.promotionEdit",
        component: Promotion,
      },
    ],
  },
];

export default routes;
