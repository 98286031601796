<template>
  <Modal
    id="suggestedProductsModal"
    :visible="visible"
    class="productsModalAddToCart"
    :with-header="false"
  >
    <template #header>
      <div></div>
      <Button
        id="suggestedProductsModal__buttonClose"
        variant="link"
        type="button"
        class="btn-close"
        @click="openCloseFun"
      />
    </template>
    <template #body>
      <ProductsCarousel
        v-if="mostFrequentlyBoughtProducts.length > 0"
        id="mostFrequentlyBoughtCarouselModal"
        key="mostFrequentlyBoughtCarouselModal"
        size="sm"
        :products="mostFrequentlyBoughtProducts"
        :count="2"
        :title="$t('Recommended')"
        :with-format="false"
      />
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import ProductsCarousel from "@/modules/components/Products/ProductsCarousel";
import Modal from "@/modules/components/base/Modal";
import { useStore } from "vuex";
import { productUtils } from "@/modules/utils/productUtils";
import { computed, watch } from "vue";

export default {
  name: "SuggestedProductsModal",

  components: { Modal, ProductsCarousel },

  setup() {
    const store = useStore();
    let { mostFrequentlyBoughtProducts, querySuggestProducts } = productUtils();

    const visible = computed(
      () => store.getters["ui/showModalSuggestedProducts"]
    );

    const openCloseFun = () => {
      store.dispatch("ui/onShowModalSuggestedProductsModal");
    };

    watch(
      () => visible.value,
      (newValue) => {
        if (newValue === true) {
          querySuggestProducts("sales");
        }
      }
    );
    return {
      openCloseFun,
      mostFrequentlyBoughtProducts,
      visible,
    };
  },
};
</script>

<style scoped></style>
