const Categories = () => import("@/modules/views/Products/Categories");
const LowPrices = () => import("@/modules/views/Products/LowPrices");
const FavoriteProducts = () =>
  import("@/modules/views/Products/FavoriteProducts");
const Products = () => import("@/modules/views/Products/Products");
const Product = () => import("@/modules/views/Products/Product");

const routes = [
  {
    path: "/produkty",
    redirect: "/products",
    name: "products",
    component: Products,

    meta: {
      label: "Produkty",
    },

    children: [
      {
        path: "/produkty",
        name: "products.products",
        component: Categories,
      },
      {
        path: "kategorie/:id",
        name: "products.category",
        component: Categories,
      },

      {
        path: "producenci/:id",
        name: "products.manufacturer",
        component: Categories,
      },

      {
        path: "lekiTrudnoDostepne/:id",
        name: "products.hardToFound",
        component: Categories,
      },

      {
        path: ":productId",
        name: "products.product",
        component: Product,
      },

      {
        path: "/produkty/szukaj",
        name: "products.search",
        component: Categories,
      }
    ],
  },
  {
    path: "/low-prices",
    name: "lowPrices",
    component: LowPrices,

    meta: {
      label: "Niskie ceny",
    },
  },
  {
    path: "/kdw",
    name: "shortDates",
    component: LowPrices,

    meta: {
      label: "Krótkie daty",
    },
  },
  {
    path: "/produktyUlubione",
    name: "favoriteProducts",
    component: FavoriteProducts,

    meta: {
      label: "Produkty ulubione",
    },
  },
  {
    path: "/producenci/:name/:id",
    redirect: (to) => {
      return {
        name: "products.products",
        query: {
          manufacturerName: to.params.name,
        },
      };
    },
  },
];

export default routes;
