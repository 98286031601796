import uiStore from "@/store/index";
import api from "@/store/axiosPlugin";
import i18n from "@/i18n";

const store = {
  namespaced: true,

  state: {},
  mutations: {},
  actions: {
    async getContactFormValues({ commit }) {
      const res = await api.get(`/user/contact`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async sendContactForm({ commit }, payload) {
      try {
        const res = await api.post(`/user/contact`, payload);

        if (!res.data) {
          throw new Error(i18n.t("Error occured while processing your request."));
        }

        return Promise.resolve(res.data);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          return Promise.reject(error);
        } else {
          return Promise.reject(
            new Error("Error occurred while processing your request.")
          );
        }
      }
    },
  },
  getters: {},
};
export default store;
