<template>
  <div class="productWithBadge productWithBadgeSkeleton">
    <div class="productWithBadge__wrapper">
      <div class="productImageWrapper">
        <div
          class="productWithBadge__imgWrapper productWithBadgeSkeleton__imgWrapper"
        ></div>
        <div class="placeholder productWithBadgeSkeleton__img" />
      </div>
      <div class="productWithBadge__main placeholder-glow">
        <div class="placeholder productWithBadgeSkeleton__productName">
          Nazwa produktu bardzo długa
        </div>
        <div class="placeholder-glow">
          <div class="placeholder w-75">00,00 zł netto</div>
          <div class="placeholder w-75">00,00 zł brutto</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductWithBadgeSkeleton",
};
</script>

<style scoped></style>
