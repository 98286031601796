<template>
  <div class="cookieAndPrivacyINFO privacy" v-if="show">
    <template v-if="modalPage === 1">
      <CookiesPage1
        @changePage="changePage"
        @saveNecessary="saveNecessaryCookie"
        @saveAll="saveAllCookie"
      />
    </template>
    <template v-if="modalPage === 2">
      <CookiesPage2
        @changePage="changePage"
        @saveDefaultCookies="saveDefaultCookies"
        @saveAll="saveAllCookie"
        :idSaveCookies="idSaveCookies"
      />
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const CookiesPage1 = defineAsyncComponent(() => import("./cookiesPage1"));
const CookiesPage2 = defineAsyncComponent(() => import("./cookiesPage2"));

export default {
  name: "cookiesPolicy",

  components: { CookiesPage2, CookiesPage1 },

  data() {
    return {
      cookiePolicyAccepted: false,
      show: false,
      modalPage: 1,
      idSaveCookies: { necessary: true },
    };
  },

  methods: {
    removeCookies() {
      const cookieOnWebsite = {
        statistics: [
          "_ga_2065MFPQH5",
          "_ga_HC8TZQD66G",
          "__ca__chat",
          "_ga",
          "_ga",
          "_ym_visorc",
          "_gid",
          "_ym_d",
          "_ym_uid",
          "__ca__chat",
          "_ga",
          "_ueuuid",
          "yabs-sid",
          "ymex",
          "yandexuid",
          "yuidss",
          "i",
          "_ym_d",
        ],
        preferences: ["pma", "callpageCookieTest", "callpage-widget-version"],
      };

      const cookiesId = this.idSaveCookies;
      if (!cookiesId.statistics) {
        cookieOnWebsite.statistics.map((cookie) => {
          if (this.$cookies[cookie]) {
            this.$cookies.remove(cookie);
            document.cookie = `${cookie}=`;
          }
        });
      }
      if (!cookiesId.preferences) {
        cookieOnWebsite.preferences.map((cookie) => {
          if (this.$cookies[cookie]) {
            this.$cookies.remove(cookie);
            document.cookie = `${cookie}=`;
          }
        });
      }
    },

    expirationTimes() {
      let expirationDays = 30;
      let expirationTime = expirationDays * 12 * 24 * 60 * 60 * 1000;
      let dateOfExpiration = new Date();
      dateOfExpiration.setTime(dateOfExpiration.getTime() + expirationTime);
      return dateOfExpiration;
    },

    setPolicyCookie() {
      let dateOfExpiration = this.expirationTimes();
      this.$cookies.set(
        "cookiePolicy",
        { accepted: true, ...this.idSaveCookies },
        dateOfExpiration
      );
      this.modalPage = 1;
      this.cookiePolicyAccepted = true;
      this.onHide();
    },

    saveNecessaryCookie() {
      this.idSaveCookies = { necessary: true };
      this.setPolicyCookie();
    },

    saveAllCookie() {
      this.idSaveCookies = {
        necessary: true,
        statistics: true,
        preferences: true,
      };
      this.setPolicyCookie();
    },

    saveDefaultCookies() {
      this.removeCookies();
      this.setPolicyCookie();
    },

    changePage(page) {
      this.modalPage = page;
    },

    checkAll() {
      this.idSaveCookies = {
        necessary: true,
        statistics: true,
        preferences: true,
      };
    },

    onHide() {
      this.show = false;
    },
  },

  mounted() {
    const cookiePolicyAccepted =
      this.$cookies.get("cookiePolicy") &&
      this.$cookies.get("cookiePolicy").accepted === true;
    if (!cookiePolicyAccepted) {
      this.show = true;
      this.cookiePolicyAccepted = false;
    } else {
      this.cookiePolicyAccepted = true;
      this.idSaveCookies = cookiePolicyAccepted;
      this.removeCookies();
    }
  },

  watch: {
    idSaveCookies(newValue, oldValue) {
      if (
        this.cookiePolicyAccepted === true &&
        newValue.idSaveCookies !== oldValue.idSaveCookies
      ) {
        this.removeCookies();
      }
    },
  },
};
</script>

<style scoped></style>
