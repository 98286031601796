import { computed, ref } from "vue";
import stamp from "@/assets/img/narcotic_stamp.png";
import productImg from "@/assets/img/produkt-img.jpg";
import productImgSm from "@/assets/img/produktImgSm.png";
import { find } from "lodash-es";
import { useStore } from "vuex";

export function productUtils() {
  const store = useStore();
  const productDefaultImg = ref(productImg);
  const productDefaultImgSm = ref(productImgSm);
  const isInCart = ref(false);
  const narcoticStamp = ref(stamp);
  const count = ref(1);
  const productsFromTheSameCategory = ref([]);
  const mostFrequentlyBoughtProducts = ref([]);
  const productsSuggestedToProducts = ref([]);

  const cartProductsList = computed(() => {
    let cartProducts = [];
    store.getters["order/cart"].cartWarehouses.map((cartWarehouse) => {
      cartProducts = [
        ...cartProducts,
        ...cartWarehouse.coldProducts,
        ...cartWarehouse.warmProducts,
      ];
    });
    return cartProducts;
  });

  const checkCount = (id) => {
    const product = find(cartProductsList.value, ["productId", Number(id)]);
    if (product !== undefined) {
      count.value = 1;
      isInCart.value = false;
    }
  };

  const querySuggestProductsForId = async (id, option) => {
    let result = await store.dispatch("extras/getSuggestProductsForId", {
      id: id,
      option: option,
    });
    productsFromTheSameCategory.value = result.map((product) => {
      product.withFormat = false;
      return product;
    });
  };

  const querySuggestProductsForIdOptions = async (id, option) => {
    let result = await store.dispatch("extras/getSuggestProductsForId", {
      id: id,
      option: option,
    });
    productsSuggestedToProducts.value = result.map((product) => {
      product.withFormat = false;
      return product;
    });
  };

  const querySuggestProducts = async (option) => {
    let result = await store.dispatch("extras/getSuggestProducts", {
      option: option,
    });
    mostFrequentlyBoughtProducts.value = result.map((product) => {
      product.withFormat = false;
      return product;
    });
  };

  return {
    store,
    productDefaultImg,
    isInCart,
    narcoticStamp,
    count,
    productDefaultImgSm,
    checkCount,
    querySuggestProductsForId,
    querySuggestProductsForIdOptions,
    querySuggestProducts,
    productsSuggestedToProducts,
    productsFromTheSameCategory,
    mostFrequentlyBoughtProducts,
    cartProductsList,
  };
}
