<template>
  <div
    v-if="openClose"
    :class="`modal fade show modal-dialog-centered modal-${size}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-modal="true"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <hr v-if="withHeader" />
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "Modal",

  props: {
    visible: Boolean,
    withHeader: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: () => "lg",
    },
  },

  setup(props) {
    const openClose = ref(props.visible);

    watch(
      () => props.visible,
      (newValue) => {
        openClose.value = newValue;
      }
    );

    return {
      openClose,
    };
  },
};
</script>

<style scoped></style>
