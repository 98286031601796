const Cart = () => import("@/modules/views/Cart/CartView");
const CartOrder = () => import("@/modules/views/Cart/CartOrder");
const CartOrderSummary = () => import("@/modules/views/Cart/CartOrderSummary");

const routes = [
  {
    path: "/koszyk",
    name: "cart",
    component: Cart,

    meta: {
      label: "Koszyk",
    },

    children: [
      {
        path: "",
        name: "cart.order",
        component: CartOrder,
      },
      {
        path: ":orderId",
        name: "cart.summary",
        component: CartOrderSummary,
      },
    ],
  },
];

export default routes;
