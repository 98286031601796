import i18n from "@/i18n";
import router from "@/router";
import api from "@/store/axiosPlugin";

const store = {
  namespaced: true,

  state: {
    user: JSON.parse(localStorage.getItem("userData")) || null,
    status: JSON.parse(localStorage.getItem("userStatus")) || null,
  },

  mutations: {
    login(state, user) {
      state.user = user;
    },
    logout(state) {
      state.user = null;
    },

    setCurrentUser(state, user) {
      state.user = user;
    },
  },

  actions: {
    async login({ commit, dispatch }, credentials) {
      const res = await api.post(`/login_check`, credentials);

      if (!res.data.user) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      localStorage.setItem("userData", JSON.stringify(res.data.user));
      localStorage.setItem("userStatus", JSON.stringify(res.data.status));
      await dispatch("fetchCurrentUser");

      commit("login", res.data.user);

      return res.data;
    },

    async logout({ commit }) {
      const res = await api.post(`/logout`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      localStorage.removeItem("userData");
      localStorage.removeItem("userStatus");

      commit("logout");

      router.push({ name: "auth.login" });
    },

    fetchCurrentUser({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/current_user`)
          .then((res) => {
            if (res.data.user) {
              localStorage.setItem("userData", JSON.stringify(res.data.user));
              localStorage.setItem(
                "userStatus",
                JSON.stringify(res.data.status)
              );
            }
            commit("setCurrentUser", res.data.user);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async remindPassword({ commit }, { username }) {
      const res = await api.get(`/forgot-password/${username}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return true;
    },

    async activateAccount({ commit }, { token, password }) {
      const res = await api.post(
        `/user/register/activate/${token}`,
        JSON.stringify(password)
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return true;
    },

    async getRegisterStage1Values({ commit }) {
      const res = await api.get(`/user/register/create-user`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async registerStage1({ commit }, dataForm) {
      try {
        const res = await api.post(`/user/register/create-user`, dataForm);

        if (!res.data || res.status === 422) {
          return Promise.reject(res);
        }

        return Promise.resolve(res);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          return Promise.reject(error);
        } else {
          return Promise.reject(
            new Error("Error occurred while processing your request.")
          );
        }
      }
    },

    async getRegisterStage2Values({ commit }, token) {
      const res = await api.get(`/user/register/send-data/${token}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async registerStage2({ commit }, { token, dataForm }) {
      try {
        const res = await api.post(
          `/user/register/send-data/${token}`,
          dataForm
        );

        if (!res.data || res.status === 422) {
          return Promise.reject(res);
        }

        return Promise.resolve(res);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          return Promise.reject(error);
        } else {
          return Promise.reject(
            new Error("Error occurred while processing your request.")
          );
        }
      }
    },

    async getPharmacyData({ commit }, gifNumber) {
      const res = await api.get(`/user/register/pharmacy-data/${gifNumber}`);

      if (res.status !== 200) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getPayerData({ commit }, payload) {
      const res = await api.post(`/user/register/payer-data`, payload);

      if (res.status !== 200) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },
  },

  getters: {
    user: (state) => state.user,
    status: (state) => state.status,
    isAuthenticated: (state) => !!state.user,
  },
};

export default store;
