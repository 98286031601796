<template>
  <!--  <div>Loading</div>-->
  <div class="productCardSkeleton productCard">
    <div class="productCardSkeleton__wrapper productCard__wrapper">
      <div class="productCardSkeleton__imgWrapper">
        <div class="productCardSkeleton__productImg placeholder-glow">
          <div class="productCard__productImg placeholder"></div>
        </div>
      </div>
      <div class="productCardSkeleton__productWrapper productCard__productWrapper">
        <div class="text-start placeholder-glow">
          <span class="productCard__header placeholder col-12">Nazwa produktu bardzo długa</span>
          <span class="productCard__twoLine placeholder col-6"> Producent BLOZ 0000</span>
          <div class="productTypeIcons">
            <div class="placeholder col-2 productCardSkeleton__img" />
          </div>
        </div>
        <div class="text-end placeholder-glow d-flex justify-content-between flex-column align-items-end gap-1">
          <div class="placeholder w-75">00,00 zł netto</div>
          <div class="placeholder w-75">00,00 zł brutto</div>
          <div class="placeholder col-12">{{ $t("Product unavailable") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCardSkeleton",
};
</script>

<style scoped></style>
