<template>
  <div class="statusBadge">
    <div :class="color">
      {{ name }}
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "StatusBadge",

  props: {
    statusValue: Number,
  },

  setup(props) {
    const store = useStore();
    const name = computed(() => {
      const statuses = store.getters["account/statusOptions"];
      return statuses[props.statusValue];
    });
    const color = computed(() => {
      if ([15, 20, 26].includes(props.statusValue)) {
        return "statusBadge__wrapper red";
      } else if ([10].includes(props.statusValue)) {
        return "statusBadge__wrapper green";
      } else {
        return "statusBadge__wrapper yellow";
      }
    });
    return {
      name,
      color,
    };
  },
};
</script>

<style scoped></style>
