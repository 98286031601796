import i18n from "@/i18n";
import api from "@/store/axiosPlugin";

const store = {
  namespaced: true,

  state: {
    manufacturers: [],
    productTypes: [[]],
    markedProducts: [],
    showSmallProducts: false,
  },

  getters: {
    manufacturers: (state) => state.manufacturers,
    productTypes: (state) => state.productTypes,
    markedProducts: (state) => state.markedProducts,
    showSmallProducts: (state) => state.showSmallProducts,
  },

  mutations: {
    setManufacturers(state, payload) {
      state.manufacturers = payload;
    },
    setProductTypes(state, payload) {
      state.productTypes = payload;
    },
    setMarkedProducts(state, payload) {
      state.markedProducts = payload;
    },
    changeProductsList(state) {
      state.showSmallProducts = !state.showSmallProducts;
    },
  },

  actions: {
    async getProducts(context, { page, query, order, perPage }) {
      let q = query;
      if (order.order) {
        q.order = order.order;
      }
      if (
        query.lowPricesAreaPromotion === true ||
        query.lowPricesAreaPromotion === "true"
      ) {
        q.lowPricesAreaPromotion = "1";
      }
      if (
        query.lowPricesAreaPromotion === false ||
        query.lowPricesAreaPromotion === "false"
      ) {
        delete q.lowPricesAreaPromotion;
      }
      if (
        query.shortTimePromotion === true ||
        query.shortTimePromotion === "true"
      ) {
        q.shortTimePromotion = "1";
      }
      if (
        query.shortTimePromotion === false ||
        query.shortTimePromotion === "false"
      ) {
        delete q.shortTimePromotion;
      }
      if (query.hardToFound === true || query.hardToFound === "true") {
        q.hardToFound = "1";
      }
      if (query.hardToFound === false || query.hardToFound === "false") {
        delete q.hardToFound;
      }
      if (query.search) {
        q.name = query.search;
        delete q.search;
      }

      let itemPerPage = perPage ? perPage : 10;
      const res = await api.get(`/product/${page}/${itemPerPage}`, {
        params: q,
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getLowPricesAreaProducts(context, { isPromotion }) {
      let q = {};

      if (isPromotion) {
        q.isPromotion = isPromotion;
      }

      const res = await api.post(
        `/homepage/low_prices_area_promotion_cells`,
        q
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getLowPricesAreaProductsValues() {
      const res = await api.get(
        `/homepage/low_prices_area_promotion_cells?form=1`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getShortTimePromotionsValues() {
      const res = await api.get(`/homepage/short_time_promotion_cells?form=1`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getShortTimePromotions(context, { isPromotion }) {
      let q = {};

      if (isPromotion) {
        q.isPromotion = isPromotion;
      }
      const res = await api.post(`/homepage/short_time_promotion_cells`, q);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getFavoriteProducts() {
      const res = await api.get(`/extras/favorite-products`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getProduct(context, { id }) {
      const res = await api.get(`/product/info/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getSearchedProducts(context, { filtrationForm }) {
      const res = await api.get(`/product`, {
        params: filtrationForm,
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async addProductToFavorite(context, { id }) {
      const res = await api.post(`/extras/favorite-product/add/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async removeProductFromFavorite(context, { id }) {
      const res = await api.post(`/extras/favorite-product/delete/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getProductManufacturers({ commit }, payload) {
      const res = await api.get(`/product/manufacturers`, {
        params: payload,
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      commit("setManufacturers", res.data);
    },

    async getProductTypes(context) {
      const res = await api.get(`/product/product_types`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      context.commit("setProductTypes", res.data);
    },

    async getSuggestedProducts() {
      const res = await api.get(`/extras/admin-suggested-products`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    async getProductsSuggestionsByType({ commit }, id) {
      const res = await api.get(
        `/product/product-type/product-suggestions/${id}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    async notifyAboutAvailability({ commit }, id) {
      const res = await api.get(`/product/notify-about-product/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      return res.data;
    },

    onMarkedProducts({ commit }, payload) {
      commit("setMarkedProducts", payload);
    },

    changeProductsListView({ commit }) {
      commit("changeProductsList");
    },
  },
};

export default store;
