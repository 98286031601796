<template>
  <b-button
    :type="type"
    :disabled="disabled"
    :variant="variant"
    @click="onClick"
  >
    <slot v-if="icon === true"></slot>
    <span v-if="text !== ''" v-html="content" />
  </b-button>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Button",

  props: {
    type: {
      type: String,
    },
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: Boolean,
    },
  },

  emits: ["click"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const onClick = (data) => {
      if (data) {
        emit("click", data);
      } else {
        emit("click");
      }
    };
    const content = computed(() => {
      return t(props.text);
    });
    return {
      onClick,
      content,
    };
  },
};
</script>

<style scoped></style>
