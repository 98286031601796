import { createI18n } from "vue-i18n";
import pl from "./pl_PL/pl_PL.json";

const i18n = createI18n({
  locale: "pl",
  legacy: false,
  globalInjection: true,
  messages: {
    pl: pl,
  },

  numberFormats: {
    pl: {
      currency: {
        style: "currency",
        currency: "PLN",
        currencyDisplay: "symbol",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
});

export default i18n;
