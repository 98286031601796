<template>
  <div v-if="packageItem" class="promotionCard">
    <div class="promotionCard__wrapper align-items-start">
      <div class="promotionCard__manufacturer">
        <span>{{ packageItem.manufacturer || "" }}</span>
      </div>
      <div class="promotionCard__typeWrapper">
        <div class="promotionCard__nameWrapper">
          <div class="promotionCard__name">
            {{ packageItem.name }}
          </div>
          <div class="packageCardArea__discountWrapper">
            <div
              :class="
                packageItem.discount
                  ? 'packageCardArea__discount'
                  : 'packageCardArea__withoutDiscount'
              "
            >
              <!--              <span v-if="packageItem.pharmacy_package.discount">-->
              <!--                {{ packageItem.pharmacy_package.discount }}</span-->
              <!--              >-->
              %
            </div>
            <img :src="backgroundIcon" alt="icon" :width="75" :height="78" />
          </div>
        </div>
        <div class="promotionCard__type mt-3">
          {{ packageItem.type }}
        </div>
      </div>
      <Button
        :id="`promotionCard__buttonPromotion${packageItem.id}`"
        text="button.See"
        @click="onClick"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/modules/components/base/Button";
import { promotionUtils } from "@/modules/utils/promotionUtils";
import backgroundIcon from "@/assets/img/promotionBackground.png";
import { gtmUtils } from "@/modules/utils/gtmUtils";
import { useRouter } from "vue-router";

export default {
  name: "PromotionCard",

  components: { Button },

  props: {
    packageItem: {
      type: Object,
      default: {},
    },
  },

  async setup(props) {
    let { changeType } = promotionUtils();
    const router = useRouter();
    const { sendToGTM } = gtmUtils();

    const sendPromotionDataToGTM = (item) => {
      let promotion = {
        name: item.name,
        id: item.id,
        price: "",
        promotion: true,
      };

      sendToGTM({
        event: "LowPriceZone",
        ecommerce: {
          show: {
            product: promotion,
          },
        },
      });
    };

    const onClick = () => {
      sendPromotionDataToGTM(props.packageItem);
      router.push({
        name: "promotions.promotion",
        params: {
          pharmacyPackageId: props.packageItem.id,
          type: changeType(props.packageItem.type),
        },
      });
    };

    await new Promise((res) => setTimeout(res, 2000));

    return {
      changeType,
      backgroundIcon,
      onClick,
    };
  },
};
</script>

<style scoped></style>
