<template>
  <Modal
    id="productsModalAddToCart"
    :visible="visible"
    class="productsModalAddToCart"
    size="xl"
  >
    <template #header>
      <h4>
        {{
          quantityOfPackagesIncreased === true
            ? $t("The package has been added to the cart")
            : $t("The product has been added to the cart")
        }}
      </h4>
      <Button
        id="productsModalAddToCart__buttonClose"
        type="button"
        class="btn-close"
        variant="link"
        @click="openCloseFun"
      />
    </template>
    <template #body>
      <Suspense>
        <ProductCard
          :id="`productCard-${product.id}`"
          :key="product.id"
          :product="product"
          :show-count="true"
          :suggested-products="false"
          :with-format="product.hasOwnProperty('withFormat') ? product.withFormat : true"
        />
        <template #fallback>
          <ProductCardSkeleton />
        </template>
      </Suspense>
      <div class="d-flex justify-content-between my-4 mx-5">
        <Button
          id="productsModalAddToCart__buttonBuyContinue"
          text="button.BUY-CONTINUE"
          variant="outline-primary"
          class="mr-5"
          @click="openCloseFun"
        />
        <Button
          id="productsModalAddToCart__buttonGoToCart"
          text="button.GO-TO-CART"
          variant="primary"
          @click="goToCart"
        />
      </div>
      <hr />
      <ProductsCarousel
        v-if="mostFrequentlyBoughtProducts.length > 0"
        :id="`mostFrequentlyBoughtCarousel-${product.id}`"
        :key="product.id"
        size="sm"
        :products="mostFrequentlyBoughtProducts"
        :count="3"
        :title="$t('Recommended')"
        :with-format="false"
      />
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal";
import ProductCard from "@/modules/components/Products/ProductItem/ProductCard";
import Button from "@/modules/components/base/Button";
import { useRouter } from "vue-router";
import { productUtils } from "@/modules/utils/productUtils";
import ProductsCarousel from "@/modules/components/Products/ProductsCarousel";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import ProductCardSkeleton from "@/modules/components/Products/ProductItem/ProductCardSkeleton";

export default {
  name: "ProductsModalAddToCart",

  components: {
    ProductCardSkeleton,
    ProductsCarousel,
    Button,
    ProductCard,
    Modal,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    let { mostFrequentlyBoughtProducts, querySuggestProducts } = productUtils();

    const visible = computed(() => store.getters["ui/showModalAddToCart"]);
    const product = computed(() => store.getters["ui/productInModal"]);
    const quantityOfPackagesIncreased = computed(
      () => store.getters["order/quantityOfPackagesIncreased"]
    );

    const openCloseFun = () => {
      store.dispatch("ui/onShowModalAddToCart", {});
    };

    const goToCart = () => {
      openCloseFun();
      router.push({ name: "cart.order" });
    };

    watch(
      () => visible.value,
      (newValue) => {
        if (newValue === true) {
          querySuggestProducts("suggested");
        }
      }
    );

    return {
      openCloseFun,
      goToCart,
      mostFrequentlyBoughtProducts,
      visible,
      product,
      quantityOfPackagesIncreased,
    };
  },
};
</script>

<style scoped></style>
