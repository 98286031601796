import { createStore } from "vuex";
import { NODE_ENV } from "../config/index";

import auth from "@/store/modules/auth";
import dashboard from "@/store/modules/dashboard";
import order from "@/store/modules/order";
import account from "@/store/modules/account";
import products from "@/store/modules/products";
import ui from "@/store/modules/ui";
import extras from "@/store/modules/extras";
import promotions from "@/store/modules/promotions";
import contactForm from "@/store/modules/contactForm";

const store = createStore({
  modules: {
    auth: auth,
    dashboard: dashboard,
    order: order,
    account: account,
    products: products,
    ui: ui,
    extras: extras,
    promotions: promotions,
    contactForm: contactForm,
  },
  strict: NODE_ENV !== "production",
  plugins: [],
});

export default store;
