<template>
  <div class="productImageWrapper">
    <div class="badge__badge">
      <div v-if="isHit === true" class="badge__badge--red">
        {{ $t("Our hit") }}
      </div>
      <div v-else-if="isNew" class="badge__badge--blue">
        {{ $t("New") }}
      </div>
    </div>
    <img :src="imgPath" alt="image" class="productImageWrapper__img" />
    <ProductTypeIcons
      :product-type="productType"
      :is-cooler-required="isCoolerRequired"
      :is-promotion="isPromotion"
    />
  </div>
</template>

<script>
import ProductTypeIcons from "@/modules/components/base/ProductTypeIcons";

export default {
  name: "ProductImageWrapper",
  components: { ProductTypeIcons },
  props: {
    isHit: {
      type: Boolean,
      default: false,
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    imgPath: {
      type: String,
      default: "",
    },

    productType: {
      type: Object,
      default: () => {},
    },

    isCoolerRequired: {
      type: Boolean,
      default: false,
    },

    isPromotion: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
