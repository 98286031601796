import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import i18n from "@/i18n";
import "./assets/index.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import BootstrapVue3 from "bootstrap-vue-3";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import VueCookies from "vue-cookies";
import { createGtm } from "@gtm-support/vue-gtm";
// import * as Sentry from "@sentry/vue";
// import { NODE_ENV } from "@/config";

let statisticCookieAgreement = false;
if (VueCookies.get("cookiePolicy")) {
  statisticCookieAgreement = VueCookies.get("cookiePolicy").statistics;
}

const app = createApp({
  render: () => h(App),
});

// if (NODE_ENV !== "development") {
//   Sentry.init({
//     app,
//     dsn: "https://6a837829827c4099a0ee8399742ef0c3@o160140.ingest.sentry.io/4504960647102464",
//     ignoreErrors: [/\b401\b/],
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
//         environment: NODE_ENV,
//       }),
//     ],
//     tracesSampleRate: "production" === NODE_ENV ? 0.2 : 1,
//     tracingOptions: {
//       trackComponents: true,
//     },
//     attachProps: true,
//     attachStacktrace: true,
//   });
// }

app.use(router);
app.use(store);
app.use(i18n);
app.use(BootstrapIconsPlugin);
app.use(BootstrapVue3);
app.use(VueCookies);
app.use(
  createGtm({
    id: "GTM-TG9386H",
    enabled: statisticCookieAgreement === true,
    vueRouter: router,
    vueRouterAdditionalEventData: () => {
      const user = store.getters["auth/user"];
      let response = {
        event: "Pageview",
        pagePath: router.currentRoute.value.fullPath,
        pageTitle: router.currentRoute.value.name,
      };
      if (user !== null) {
        response.user_id = user.pharmacyNettleId;
        response.email = user.email;
        response.in_edtp_club = user.inEdtpClub;
      }
      return response;
    },
  })
);
app.mount("#app");
