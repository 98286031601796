<template>
  <Modal :visible="visible" id="personalizedPopupThanks" class="personalizedPopups">
    <template #header>
      <div class="personalizedPopups__header">
        <Button variant="link" type="button" class="btn-close" @click="onHide" id="personalizedPopupThanks__buttonClose" />
      </div>
    </template>
    <template #body>
      <div class="personalizedPopups">
        <div class="personalizedPopups__header">
          <i class="fa fa-times" onClick="{this.onHide}" />
        </div>
        <div class="personalizedPopups__mainSection">
          <div class="personalizedPopups__productSection">
            <div><img :src="smileImage" alt="smile" :width="154" :height="154" /></div>
            <div class="personalizedPopups__text7 text-center">
              Dziękujemy <br />
              za Twoją opinię!
            </div>
          </div>
          <div>
            <button class="personalizedPopups__button buttonSubmit" @click="onHide" id="personalizedPopupThanks__buttonGoToEdtp">
              Przejdź do eDTP
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #footer> </template>
  </Modal>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import Modal from "@/modules/components/base/Modal";
import smileImage from "@/assets/img/smile.png";
import Button from "@/modules/components/base/Button";

export default {
  name: "PersonalizedPopupThanks",

  components: { Button, Modal },

  setup() {
    const store = useStore();

    const visible = computed(() => {
      return store.getters["extras/showPersonalizedPopupId"] === 4;
    });

    const onHide = () => {
      store.dispatch("extras/showPersonalizedPopup", 0);
      store.dispatch("extras/addProductPopup", {});
    };

    return {
      visible,
      onHide,
      smileImage,
    };
  },
};
</script>

<style scoped></style>
