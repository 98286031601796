<template>
  <Modal
    id="productsModalAddToCartForSeveralProducts"
    :visible="visible"
    class="productsModalAddToCart"
    :with-header="false"
  >
    <template #header>
      <div></div>
      <Button
        id="productsModalAddToCartForSeveralProducts__buttonClose"
        variant="link"
        type="button"
        class="btn-close"
        @click="openCloseFun"
      />
    </template>
    <template #body>
      <div class="d-flex justify-content-center align-items-center my-4 mx-5">
        <h4>
          {{ $t("The selected products have been added to the cart") }}
        </h4>
      </div>
      <div class="d-flex justify-content-between my-4 mx-5">
        <Button
          id="productsModalAddToCartForSeveralProducts__buttonBuyContinue"
          text="button.BUY-CONTINUE"
          variant="outline-primary"
          class="mr-5"
          @click="openCloseFun"
        />
        <Button
          id="productsModalAddToCartForSeveralProducts__buttonGoToCart"
          text="button.GO-TO-CART"
          variant="primary"
          @click="goToCart"
        />
      </div>
      <hr />
      <ProductsCarousel
        v-if="mostFrequentlyBoughtProducts.length > 0"
        id="mostFrequentlyBoughtCarouselModal"
        key="mostFrequentlyBoughtCarouselModal"
        size="sm"
        :products="mostFrequentlyBoughtProducts"
        :count="2"
        :title="$t('Recommended')"
      />
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal";
import Button from "@/modules/components/base/Button";
import { useRouter } from "vue-router";
import { productUtils } from "@/modules/utils/productUtils";
import ProductsCarousel from "@/modules/components/Products/ProductsCarousel";
import { computed, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProductsModalAddToCartForSeveralProducts",

  components: { ProductsCarousel, Button, Modal },

  setup() {
    const router = useRouter();
    const store = useStore();
    let { mostFrequentlyBoughtProducts, querySuggestProducts } = productUtils();

    const visible = computed(
      () => store.getters["ui/showModalAddToCartForSeveralProducts"]
    );

    const openCloseFun = () => {
      store.dispatch("ui/onShowModalAddToCartForSeveralProducts");
    };

    const goToCart = () => {
      openCloseFun();
      router.push({ name: "cart.order" });
    };

    watch(
      () => visible.value,
      (newValue) => {
        if (newValue === true) {
          querySuggestProducts("suggested");
        }
      }
    );

    return {
      openCloseFun,
      goToCart,
      mostFrequentlyBoughtProducts,
      visible,
    };
  },
};
</script>

<style scoped></style>
