import i18n from "@/i18n";
import api from "@/store/axiosPlugin";

const store = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    async getInformationBarItems({ commit }) {
      const res = await api.get(`/extras/information-bar`, {
        withCredentials: true,
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data[0];
    },
  },
};

export default store;
