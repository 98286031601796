import { ref, watch } from "vue";
import { useStore } from "vuex";

export function counterUtils(props, emit) {
  const store = useStore();
  const itemValue = ref(props.count);

  const onReduce = () => {
    itemValue.value = Number(itemValue.value) - 1;
    if (props.withButton === false) {
      emitValue();
    }
  };

  const onChangeValueByInput = (count) => {
    itemValue.value = count;
    if (props.withButton === false) {
      emitValue();
    }
  };

  const onAdd = () => {
    itemValue.value = Number(itemValue.value) + 1;
    if (props.withButton === false) {
      emitValue();
    }
  };

  const emitValue = (product) => {
    emit("changeValue", itemValue.value);
    if (props.withModal === true) {
      product.count = itemValue.value;
      store.dispatch("ui/onShowModalAddToCart", product);
      itemValue.value = 1;
    }
  };

  watch(
    () => props.count,
    (newValue) => {
      itemValue.value = newValue;
    }
  );

  return {
    itemValue,
    onReduce,
    onChangeValueByInput,
    onAdd,
    emitValue,
  };
}
