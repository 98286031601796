<template>
  <div class="promotionCard promotionCardSkeleton">
    <div class="promotionCard__wrapper align-items-start placeholder-glow">
      <div class="promotionCardSkeleton__manufacturer placeholder">
        <span>Manufacturer</span>
      </div>
      <div class="promotionCard__typeWrapper">
        <div class="promotionCard__nameWrapper placeholder-glow">
          <div class="promotionCardSkeleton__name placeholder">
            Package lorem ipsum
          </div>
          <div class="packageCardArea__discountWrapper">
            <div class="promotionCardSkeleton__image placeholder"></div>
          </div>
        </div>
        <div class="promotionCard__type mt-3 placeholder">Package type</div>
      </div>
      <div><a href="#" tabindex="-1" class="placeholder col-12" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionCardSkeleton",
};
</script>

<style scoped></style>
