<template>
  <Modal id="orderRetryModal" :visible="visible">
    <template #header>
      <div>
        <h4>{{ $t("views.Retry-order") }}</h4>
        <div>
          {{ $t("Order number") }}
          <span class="bold">{{ orderId }}</span>
        </div>
      </div>
      <Button
        id="orderRetryModal__buttonClose"
        variant="link"
        type="button"
        class="btn-close"
        @click="onShowModal"
      />
    </template>

    <template #body>
      <OrderRetryModalTable
        :products="products"
        :order-value="orderValue"
        @change="onChangeOrderValue"
      />
    </template>

    <template #footer>
      <Button
        id="orderRetryModal__buttonRetry"
        variant="primary"
        text="button.Confirm"
        class="retryModalBu"
        @click="retryOrder"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import OrderRetryModalTable from "@/modules/components/Account/OrderRetryModalTable";
import Button from "@/modules/components/base/Button";
import { useRouter } from "vue-router";

export default {
  name: "OrderRetryModal",

  components: { Button, OrderRetryModalTable, Modal },

  setup() {
    const store = useStore();
    const router = useRouter();
    const products = ref({});
    const orderValue = ref([]);

    const orderId = computed(() => store.getters["account/orderId"]);
    const visible = computed(() => store.getters["account/showOrderRetry"]);

    const queryOrder = async () => {
      await store.dispatch("ui/loader", true);
      const result = await store.dispatch(
        "account/getRetryOrder",
        orderId.value
      );
      populateOrder(result);
    };

    const populateOrder = (result) => {
      products.value = result.initialValues.products;
      orderValue.value = result.initialValues.products.map((item) => {
        return { count: item.count, product: item.product.id };
      });
      store.dispatch("ui/loader", false);
    };

    const onChangeOrderValue = (productsValues) => {
      orderValue.value = productsValues;
    };

    const onShowModal = () => {
      store.dispatch("account/onShowOrderRetry", 0);
    };

    const retryOrder = () => {
      store.dispatch("ui/loader", true);
      store
        .dispatch("account/retryOrder", {
          id: orderId.value,
          payload: { products: orderValue.value },
        })
        .then(() => {
          onShowModal();
          router.push({ name: "cart.order" });
        })
        .catch(() => {})
        .finally(() => {
          store.dispatch("ui/loader", false);
        });
    };

    watch(
      () => visible.value,
      (newValue) => {
        if (newValue === true) {
          queryOrder();
        }
      }
    );

    return {
      products,
      orderId,
      visible,
      onShowModal,
      orderValue,
      onChangeOrderValue,
      retryOrder,
    };
  },
};
</script>

<style scoped></style>
