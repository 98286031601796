<template>
  <Table :fields="fields">
    <tr v-for="(item, index) in products" :key="index">
      <OrderRetryModalTableLine
        :fields="fields"
        :item="item"
        :key="index"
        @change="onChangeOrderValue"
      />
    </tr>
  </Table>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import Table from "@/modules/components/base/Table";
import OrderRetryModalTableLine from "@/modules/components/Account/OrderRetryModalTableLine";

export default {
  name: "OrderRetryModalTable",

  components: { OrderRetryModalTableLine, Table },

  props: {
    products: Array,
    orderValue: Array,
  },

  emits: ["change"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const fields = computed(() => [
      {
        key: "productId",
        title: t("form.Product"),
        scopedSlots: { customRender: "product" },
      },
      {
        key: "count",
        title: t("form.Count"),
        scopedSlots: { customRender: "count" },
      },
      {
        key: "priceSumNet",
        title: t("form.Net-value"),
        scopedSlots: { customRender: "priceSumNet" },
      },
      {
        key: "priceSumGross",
        title: t("Gross value"),
        scopedSlots: { customRender: "priceSumGross" },
      },
    ]);

    const onChangeOrderValue = (product) => {
      let list = [];
      if (props.orderValue.length > 0) {
        props.orderValue.map((item) => {
          if (item.product === product.product) {
            if (product.count !== 0) {
              list.push(product);
            }
          } else {
            if (product.count !== 0) {
              list.push(item);
            }
          }
        });
      } else {
        list.push(product);
      }
      emit("change", list);
    };

    return {
      fields,
      onChangeOrderValue,
    };
  },
};
</script>

<style scoped></style>
