import { API_URL, NODE_ENV } from "@/config";
import axios from "axios";
import store from "@/store/index";
import router from "@/router";

const api = axios.create({
  baseURL: NODE_ENV === "production" ? JSON.parse(API_URL) : API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      const isFreeAccessPage = router.currentRoute.value.matched.some(
        (record) => record.meta.freeAccess
      );

      if (isFreeAccessPage === false) {
        store.dispatch("auth/logout");
      }
    }

    store.dispatch("ui/loader", false);

    return Promise.reject(error);
  }
);


export default api;
