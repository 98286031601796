const Login = () => import("@/modules/views/Login/LoginView");
const LoginForm = () => import("@/modules/views/Login/LoginForm");
const LoginRemindPassword = () =>
  import("@/modules/views/Login/LoginRemindPassword");
const ActivateAccount = () =>
  import("@/modules/views/Login/LoginActivateAccount");
const Registration = () =>
  import("@/modules/views/Registration/RegistrationView");
const RegistrationStage1 = () =>
  import("@/modules/views/Registration/RegistrationStage1");
const RegistrationStage2 = () =>
  import("@/modules/views/Registration/RegistrationStage2");

const routes = [
  {
    path: "/auth",
    redirect: "/login",
    name: "auth",
    component: Login,

    meta: {
      freeAccess: false,
      guest: true,
      label: "Logowanie", // TODO: tłumaczenie i18n
    },

    children: [
      {
        path: "logowanie",
        name: "auth.login",
        component: LoginForm,
      },
      {
        path: "remind",
        name: "auth.remind",
        component: LoginRemindPassword,
      },
      {
        path: "aktywacja-konta/:token",
        name: "auth.activeAccount",
        component: ActivateAccount,
      },
    ],
  },
  {
    path: "/aktywacja-konta/:token",
    redirect: (to) => {
      const { token } = to.params;
      return `/auth/aktywacja-konta/${token}`;
    },
  },
  {
    path: "/rejestracja-etap-2/:id",
    redirect: (to) => {
      const { id } = to.params;
      return `/register/rejestracja-etap-2/${id}`;
    },
  },
  {
    path: "/register",
    name: "auth.register",
    component: Registration,

    meta: {
      guest: true,
    },

    children: [
      {
        path: "rejestracja",
        name: "auth.registerStage1",
        component: RegistrationStage1,

        meta: {
          label: "Rejestracja",
        },
      },
      {
        path: "rejestracja-etap-2/:id",
        name: "auth.registerStage2",
        component: RegistrationStage2,

        meta: {
          label: "Rejestracja",
        },
      },
    ],
  },
];

export default routes;
