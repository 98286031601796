<template>
  <div
    v-if="visibleDialogueModal"
    id="dialogueModal"
    class="modal fade show modal-dialog-centered modal-md dialogueModal"
    tabindex="-1"
    aria-labelledby="dialogueModalLabel"
    aria-modal="true"
    role="dialog"
    style="display: block"
  >
    <div
      class="modal-dialog dialogueModal__modal"
      v-if="dialogueVisibleDialogueModal !== {}"
    >
      <div class="modal-content dialogueModal__content">
        <div class="modal-header">
          <div></div>
          <Button
            variant="link"
            type="button"
            class="btn-close"
            @click="openCloseFun"
            id="dialogueModal__buttonClose"
          />
        </div>
        <div class="modal-body dialogueModal__body">
          <div class="dialogueModal__sectionMain">
            <div class="dialogueModal__iconContainer">
              <div
                class="dialogueModal__iconContainer--success"
                v-if="dialogueVisibleDialogueModal.mode === 'success'"
              >
                <b-icon-check-lg />
              </div>
              <div
                class="dialogueModal__iconContainer--error"
                v-if="dialogueVisibleDialogueModal.mode === 'error'"
              >
                <b-icon-exclamation-lg />
              </div>
            </div>
            <div class="dialogueModal__contentMain">
              <div
                class="dialogueModal__titleSection"
                v-html="dialogueVisibleDialogueModal.title"
              ></div>
            </div>
          </div>
          <div
            v-if="'content' in dialogueVisibleDialogueModal"
            v-html="dialogueVisibleDialogueModal.content"
            class="dialogueModal__contentSection"
          ></div>
        </div>
        <div class="modal-footer dialogueModal__footer">
          <div class="dialogueModal__buttonsWrapper">
            <Button
              :text="dialogueVisibleDialogueModal.cancelText"
              variant="primary"
              id="dialogueModal__buttonCancelText"
              @click="openCloseFun"
              v-if="'cancelText' in dialogueVisibleDialogueModal"
            />
            <Button
              :text="dialogueVisibleDialogueModal.okText"
              variant="primary"
              id="dialogueModal__buttonOkText"
              @click="openCloseFun"
              v-if="'okText' in dialogueVisibleDialogueModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Button from "@/modules/components/base/Button";

export default {
  name: "DialogueModal",

  components: { Button },

  setup() {
    const store = useStore();

    const visibleDialogueModal = computed(() => {
      return store.getters["ui/visibleDialogueModal"];
    });

    const dialogueVisibleDialogueModal = computed(() => {
      return { ...store.getters["ui/dialogueVisibleDialogueModal"] };
    });

    const openCloseFun = () => {
      store.dispatch("ui/onVisibleDialogueModal", {});
    };

    return {
      dialogueVisibleDialogueModal,
      openCloseFun,
      visibleDialogueModal,
    };
  },
};
</script>

<style scoped></style>