<template>
  <div class="personalizedPopups__product">
    <div class="personalizedPopups__text6">
      {{ product.name }}
    </div>
    <img :src="`${product.image || productDefaultImg}`" :alt="product.name" :width="245" :height="130" />
    <div class="personalizedPopups__text6">
      {{ $n(product.priceNet) }} zł netto
    </div>
    <router-link
      :to="{
        name: 'products.product',
        params: { productId: product.id },
      }"
    >
      <button class="personalizedPopups__button buttonSubmit" @click="onHide" :id="`personalizedPopups__buttonSubmitProduct${product.id}`">
        <b-icon-cart /> Przejdź do produktu
      </button>
    </router-link>
  </div>
</template>

<script>
import { productUtils } from "@/modules/utils/productUtils";

export default {
  name: "PersonalizedPopupProductCard",

  props: {
    product: Object,
  },

  emits: ["hide"],

  setup(props, { emit }) {
    const { productDefaultImg } = productUtils();

    const onHide = () => {
      emit("hide", props.product);
    };
    return {
      onHide,
      productDefaultImg,
    };
  },
};
</script>

<style scoped></style>
