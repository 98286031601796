<template>
  <div class="productPrice">
    <div class="productPrice__net">
      {{ withFormat ? $n(formatPrice(priceNet)) : $n(priceNet) }} zł
      {{ $t("net") }}
    </div>
    <div class="productPrice__gross">
      {{ withFormat ? $n(formatPrice(priceGross)) : $n(priceGross) }} zł
      {{ $t("gross") }}
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/modules/utils/format-price";

export default {
  name: "ProductPrice",

  props: {
    priceNet: Number,
    priceGross: Number,
    withFormat: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    formatPrice(price) {
      if (
        this.$route.name === "favoriteProducts"
      ) {
        return price;
      } else {
        return formatPrice(price);
      }
    },
  },
};
</script>

<style scoped></style>
