<template>
  <div :id="id" class="productsCarousel">
    <div
      :class="[
        { productsCarousel__title: true, 'mb-2': true },
        getClassForTitle,
      ]"
      @click="pushToRoute"
    >
      {{ title }}
    </div>
    <div class="carouselComponent">
      <carousel
        v-if="products.length > 0"
        :wrap-around="true"
        :breakpoints="breakpoints"
        class="carouselComponent__carousel"
      >
        <slide
          v-for="slide in products"
          :key="`productsCarousel__products${slide.id}`"
        >
          <Suspense>
            <ProductWithBadge
              :hit-badge="slide.marked || false"
              :product-data="slide.product || slide"
              :with-price="true"
              :with-format="withFormat"
            />
            <template #fallback>
              <ProductWithBadgeSkeleton />
            </template>
          </Suspense>
        </slide>

        <template #addons>
          <navigation />
        </template>
      </carousel>

      <carousel
        v-else
        :wrap-around="true"
        :breakpoints="breakpoints"
        class="carouselComponent__carousel"
      >
        <slide
          v-for="slide in promotions"
          :key="`productsCarousel__promotions${slide.id}`"
        >
          <Suspense>
            <PromotionCard :package-item="slide" :with-price="true" />
            <template #fallback>
              <PromotionCardSkeleton />
            </template>
          </Suspense>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import ProductWithBadge from "@/modules/components/Products/ProductItem/ProductWithBadge";
import PromotionCard from "@/modules/components/Promotions/PromotionCard";
import { computed, ref } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import ProductWithBadgeSkeleton from "@/modules/components/Products/ProductItem/ProductWithBadgeSkeleton";
import PromotionCardSkeleton from "@/modules/components/Promotions/PromotionCardSkeleton";
import { useRouter } from "vue-router";

export default {
  name: "ProductsCarousel",

  components: {
    PromotionCardSkeleton,
    ProductWithBadgeSkeleton,
    Carousel,
    PromotionCard,
    ProductWithBadge,
    Slide,
    Navigation,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      default: [],
    },
    promotions: {
      type: Array,
      default: [],
    },
    count: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleLink: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    withFormat: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const breakpoints = ref({
      1: {
        itemsToShow: 1,
        snapAlign: "left",
      },
      600: {
        itemsToShow: 2,
        snapAlign: "left",
      },
      880: {
        itemsToShow: 3,
        snapAlign: "left",
      },
      1400: {
        itemsToShow: props.count,
        snapAlign: "left",
      },
    });

    let getClassForTitle = computed(() => {
      if (props.titleLink) {
        return "cursor-pointer";
      } else {
        return "";
      }
    });

    const router = useRouter();

    const pushToRoute = () => {
      if (props.titleLink) {
        router.push(props.titleLink);
      }
    };
    return {
      breakpoints,
      getClassForTitle,
      pushToRoute,
    };
  },
};
</script>

<style scoped></style>
