<template>
  <Modal
    :visible="visible"
    id="personalizedPopupTemplate2"
    class="personalizedPopups"
  >
    <template #header>
      <div class="personalizedPopups__header">
        <Button variant="link" type="button" class="btn-close" @click="onHide" id="personalizedPopupTemplate2__buttonClose" />
      </div>
    </template>
    <template #body>
      <div class="personalizedPopups__mainSection">
        <div class="personalizedPopups__backgroundComponent">
          <div>
            <div
              class="personalizedPopups__text4 personalizedPopups__space1 text-center"
            >
              Specjalnie dla Ciebie w promocyjnych cenach mamy na eDTP produkty,
              które już zamawiałeś/aś.
            </div>
            <div class="personalizedPopups__text5 text-center">
              Czy chcesz zamówić je ponownie?
            </div>
          </div>
          <div class="personalizedPopups__productWrapper">
            <PersonalizedPopupProductCard
              v-for="(product, index) in products"
              :product="product"
              :key="index"
              @hide="selectProduct"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer></template>
  </Modal>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import Modal from "@/modules/components/base/Modal";
import PersonalizedPopupProductCard from "@/modules/components/base/PersonalizedPopups/PersonalizedPopupProductCard";
import Button from "@/modules/components/base/Button";

export default {
  name: "PersonalizedPopupTemplate2",

  components: { Button, PersonalizedPopupProductCard, Modal },

  setup() {
    const store = useStore();

    const visible = computed(() => {
      return store.getters["extras/showPersonalizedPopupId"] === 2;
    });

    const products = computed(() => store.getters["extras/popupProducts"]);

    const onHide = () => {
      store.dispatch("extras/showPersonalizedPopup", 0);
    };

    const addProductToState = (product) => {
      store.dispatch("extras/addProductPopup", product);
    };

    const selectProduct = (payload) => {
      addProductToState(payload);
      onHide();
    };

    return {
      visible,
      products,
      onHide,
      selectProduct,
    };
  },
};
</script>

<style scoped></style>
