import { useGtm } from "@gtm-support/vue-gtm";
import { computed } from "vue";

export function gtmUtils() {
  const gtm = useGtm();
  let enabledGTM = computed(() => {
    if (gtm) {
      return gtm.options.enabled;
    } else {
      return false;
    }
  });

  const sendToGTM = (data) => {
    if (enabledGTM.value) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    }
  };

  return {
    sendToGTM,
  };
}
