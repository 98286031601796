const Account = () => import("@/modules/views/Account/AccountView");
const UserData = () => import("@/modules/views/Account/UserData");
const HistoryOfOrders = () => import("@/modules/views/Account/HistoryOfOrders");
const Invoices = () => import("@/modules/views/Account/Invoices");
const Agreements = () => import("@/modules/views/Account/Agreements");
const Documents = () => import("@/modules/views/Account/Documents");

const routes = [
  {
    path: "/panelUzytkownika",
    redirect: "/panelUzytkownika/profil",
    name: "account",
    component: Account,

    meta: {
      freeAccess: false,
      authorized: true,
      label: "Moje konto", // TODO: tłumaczenie i18n
    },

    children: [
      {
        path: "profil",
        name: "account.userData",
        component: UserData,

        meta: {
          label: "Dane użytkownika", // TODO: tłumaczenie i18n
        },
      },
      {
        path: "historiaZamowien",
        name: "account.historyOfOrders",
        component: HistoryOfOrders,

        meta: {
          label: "Historia zamówień", // TODO: tłumaczenie i18n
        },
      },
      {
        path: "faktury",
        name: "account.invoices",
        component: Invoices,

        meta: {
          label: "Faktury", // TODO: tłumaczenie i18n
        },
      },
      {
        path: "zgody",
        name: "account.agreements",
        component: Agreements,

        meta: {
          label: "Zgody", // TODO: tłumaczenie i18n
        },
      },
      {
        path: "dokumenty",
        name: "account.documents",
        component: Documents,

        meta: {
          label: "Dokumenty", // TODO: tłumaczenie i18n
        },
      },
    ],
  },
];

export default routes;
