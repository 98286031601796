const store = {
  namespaced: true,

  state: {
    filters: {},
    sort: {},
    loader: false,
    currentProgressStage: 0,
    currentProgressSteps: [],
    showModalAddToCart: false,
    productInModal: {},
    visibleDialogueModal: false,
    dialogueVisibleDialogueModal: {},
    showModalAddToCartForSeveralProducts: false,
    showModalSuggestedProducts: false,
    openManufacturersFiltration: false,
  },

  mutations: {
    setFilters(state, filters) {
      for (let i in filters) {
        if (filters[i] === "") {
          delete filters[i];
        }
      }
      state.filters = filters;
    },

    setSort(state, { key, value }) {
      state.sort[key] = value;
    },

    setLoader(state, loader) {
      state.loader = loader;
    },

    setOpenManufacturersFiltration(state) {
      state.openManufacturersFiltration = !state.openManufacturersFiltration;
    },

    setShowModalAddToCart(state, payload) {
      state.productInModal = payload;
      state.showModalAddToCart = !state.showModalAddToCart;
    },

    setShowModalAddToCartForSeveralProducts(state) {
      state.showModalAddToCartForSeveralProducts =
        !state.showModalAddToCartForSeveralProducts;
    },

    setShowModalSuggestedProducts(state) {
      state.showModalSuggestedProducts = !state.showModalSuggestedProducts;
    },

    setProgressStage(state, stage) {
      state.currentProgressStage = stage;
    },

    setProgressSteps(state, steps) {
      state.currentProgressSteps = steps;
    },

    setVisibleDialogueModal(state, payload) {
      state.dialogueVisibleDialogueModal = payload;
      state.visibleDialogueModal = !state.visibleDialogueModal;
    },

    reset(state) {
      state.filters = {};
      state.sort = {};
    },
  },

  actions: {
    updateFilters(context, payload) {
      context.commit("setFilters", payload);
    },

    updateSort(context, payload) {
      context.commit("setSort", payload);
    },

    reset(context) {
      context.commit("reset");
    },

    loader(context, payload) {
      context.commit("setLoader", payload);
    },

    setProgressStage(context, payload) {
      context.commit("setProgressStage", payload);
    },

    setProgressSteps(context, payload) {
      context.commit("setProgressSteps", payload);
    },

    onShowModalAddToCart(context, payload) {
      context.commit("setShowModalAddToCart", payload);
    },

    onShowModalAddToCartForSeveralProducts({ commit }) {
      commit("setShowModalAddToCartForSeveralProducts");
    },

    onShowModalSuggestedProductsModal({ commit }) {
      commit("setShowModalSuggestedProducts");
    },

    onVisibleDialogueModal({ commit }, payload) {
      commit("setVisibleDialogueModal", payload);
    },

    onOpenManufacturersFiltration({ commit }) {
      commit("setOpenManufacturersFiltration");
    },
  },

  getters: {
    filters: (state) => state.filters,
    sort: (state) => state.sort,
    loader: (state) => state.loader,
    currentProgressStage: (state) => state.currentProgressStage,
    currentProgressSteps: (state) => state.currentProgressSteps,
    showModalAddToCart: (state) => state.showModalAddToCart,
    showModalAddToCartForSeveralProducts: (state) =>
      state.showModalAddToCartForSeveralProducts,
    showModalSuggestedProducts: (state) => state.showModalSuggestedProducts,
    productInModal: (state) => state.productInModal,
    dialogueVisibleDialogueModal: (state) => state.dialogueVisibleDialogueModal,
    visibleDialogueModal: (state) => state.visibleDialogueModal,
    openManufacturersFiltration: (state) => state.openManufacturersFiltration,
  },
};

export default store;
