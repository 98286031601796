import i18n from "@/i18n";
import api from "@/store/axiosPlugin";
import download from "downloadjs";
import uiStore from "@/store/index";

const store = {
  namespaced: true,

  state: {
    statusOptions: {},
    orderId: 0,
    showOrderDetail: false,
    showOrderRetry: false,
    agreements: [],
  },

  mutations: {
    setStatus(state, payload) {
      const properties = payload.form.properties;
      let options = {};
      properties.status.enum_titles.map((item, index) => {
        options[properties.status.enum[index]] = item;
      });
      state.statusOptions = options;
    },

    setShowOrderDetail(state, payload) {
      state.showOrderDetail = !state.showOrderDetail;
      state.orderId = payload;
    },

    setShowOrderRetry(state, payload) {
      state.showOrderRetry = !state.showOrderRetry;
      state.orderId = payload;
    },

    setAgreements(state, payload) {
      state.agreements = payload;
    },
  },

  actions: {
    async getUserData({ commit }) {
      const res = await api.get(`/user/pharmacyData/edit`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async saveUserData({ commit }, dataForm) {
      const res = await api.post(`/user/pharmacyData/edit`, dataForm);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async changePassword({ commit }, dataForm) {
      const res = await api.post(`/change_password`, dataForm);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getOrders({ commit }, { page, perPage }) {
      let q = {
        ...uiStore.getters["ui/filters"],
      };

      const res = await api.get(
        `/order/get-orders/${page}/${perPage}`,
        { params: q }
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getOrdersValues({ commit }, { page, perPage }) {
      const res = await api.post(
        `/order/get-orders/${page}/${perPage}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setStatus", res.data);

      return res.data;
    },

    async getOrder({ commit }, id) {
      const res = await api.get(`/order/get-order/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async cancelOrder({ commit }, id) {
      const res = await api.get(`/order/cancel/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getRetryOrder({ commit }, id) {
      const res = await api.get(`/order/reply-order/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async retryOrder({ commit }, { id, payload }) {
      const res = await api.post(`/order/reply-order/${id}`, payload);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getInvoices({ commit }, { page, perPage }) {
      let q = {
        ...uiStore.getters["ui/filters"],
      };

      let sort = { ...uiStore.getters["ui/sort"] };

      let sortedParams = `sort[createDate]=${
        sort["createDate"] || "desc"
      }&sort[invoiceIssuer]=${
        sort["invoiceIssuer"] || "desc"
      }&sort[invoiceNumber]=${sort["invoiceNumber"] || "desc"}`;

      if (page) {
        q.page = page;
      }

      if (perPage) {
        q.perPage = perPage;
      }

      const res = await api.get(`/invoices/?${sortedParams}`, {
        params: q,
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getInvoicesProform({ commit }, { page, perPage }) {
      let q = {
        ...uiStore.getters["ui/filters"],
      };

      if (page) {
        q.page = page;
      }

      if (perPage) {
        q.perPage = perPage;
      }

      const res = await api.get(`/proform/`, { params: q });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async getDocuments({ commit }) {
      const res = await api.get(`/user/documents`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async downloadDocument({ commit }, { documentType, id }) {
      const res = await api.get(`/user/documents/${id}`, {
        responseType: "blob",
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      download(res.data, `${documentType}_${id}.pdf`, "application/pdf");
    },

    async getAgreements({ commit }) {
      const res = await api.get(`/user/agreements`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setAgreements", res.data);

      return res.data;
    },

    async agreementAccept({ commit }, { id }) {
      const res = await api.get(`/user/agreements/accept/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setAgreements", res.data);

      return res.data;
    },

    async agreementDecline({ commit }, { id }) {
      const res = await api.get(`/user/agreements/decline/${id}`);

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      commit("setAgreements", res.data);

      return res.data;
    },

    async checkStatusAgreement({ commit }, { id }) {
      const res = await api.get(
        `/user/agreements/invoice-issuer/${id}`
      );

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      return res.data;
    },

    async downloadInvoice({ commit }, { id, format, invoiceNumber }) {
      const res = await api.get(`/invoices/${id}/${format}`, {
        responseType: "blob",
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }

      if (format === "pdf") {
        download(res.data, `${invoiceNumber}.pdf`, "application/pdf");
      } else {
        download(res.data, `${invoiceNumber}.xml`, "application/xml");
      }
    },

    async downloadInvoiceProform({ commit }, id) {
      const res = await api.get(`/proform/download/${id}`, {
        responseType: "blob",
      });

      if (!res.data) {
        throw new Error(i18n.t("Error occured while processing your request."));
      }
      download(res.data, `Faktura-proforma-${id}.pdf`, "application/pdf");
    },

    onShowOrderDetail({ commit }, payload) {
      commit("setShowOrderDetail", payload);
    },

    onShowOrderRetry({ commit }, payload) {
      commit("setShowOrderRetry", payload);
    },
  },

  getters: {
    statusOptions: (state) => state.statusOptions,
    showOrderDetail: (state) => state.showOrderDetail,
    orderId: (state) => state.orderId,
    showOrderRetry: (state) => state.showOrderRetry,
    agreements: (state) => state.agreements,
  },
};

export default store;
