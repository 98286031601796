<template>
  <Modal
    :visible="visible"
    id="personalizedPopupTemplate1"
    class="personalizedPopups"
  >
    <template #header>
      <div class="personalizedPopups__header">
        <Button variant="link" type="button" class="btn-close" @click="onHide" id="personalizedPopupTemplate1__buttonClose" />
      </div>
    </template>
    <template #body>
      <div class="personalizedPopups__mainSection">
        <div>
          <div class="personalizedPopups__text1 personalizedPopups__space1">
            Przypominamy!
          </div>
          <div class="personalizedPopups__text2">
            Lek
            <span class="personalizedPopups__text3">{{ product.name }}</span>
            jest dostępny <br />
            na platformie eDTP.
          </div>
        </div>
        <div class="text-center">
          <router-link
            :to="{
              name: 'products.product',
              params: { productId: product.id },
            }"
          >
            <button class="personalizedPopups__button buttonSubmit" @click="onHide" id="personalizedPopupTemplate1__buttonSubmit">
              Sprawdź<br />
              dostępne dawki
            </button>
          </router-link>
        </div>
      </div>
    </template>
    <template #footer> </template>
  </Modal>
</template>

<script>
import Modal from "@/modules/components/base/Modal";
import { computed } from "vue";
import { useStore } from "vuex";
import Button from "@/modules/components/base/Button";

export default {
  name: "PersonalizedPopupTemplate1",

  components: { Button, Modal },

  setup() {
    const store = useStore();

    const visible = computed(() => {
      return store.getters["extras/showPersonalizedPopupId"] === 1;
    });

    const product = computed(() => store.getters["extras/popupProduct"]);

    const onHide = () => {
      store.dispatch("extras/showPersonalizedPopup", 0);
      store.dispatch("extras/addProductPopup", {});
    };

    return {
      visible,
      product,
      onHide,
    };
  },
};
</script>

<style scoped></style>
