<template>
  <div id="app">
    <router-view></router-view>
    <CookiesPolicy />
    <Notification />
    <Loader />
    <ProductsModalAddToCart />
    <ProductsModalAddToCartForSeveralProducts />
    <SuggestedProductsModal />
    <DialogueModal />
    <PersonalizedPopupTemplate1 />
    <PersonalizedPopupTemplate2 />
    <PersonalizedPopupForm />
    <PersonalizedPopupThanks />
    <StadaPopup />
    <OrderDetailsModal />
    <OrderRetryModal />
    <Dialog />
    <button
      type="button"
      class="arrowButton"
      title="Przescrolluj do góry"
      @click="scrollToTop"
    >
      <b-icon-arrow-up />
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CookiesPolicy from "@/modules/components/WebsitePolicy/cookiesPolicy";
import Notification from "@/modules/components/WebsitePolicy/Notification";
import Loader from "@/modules/components/base/Loader";
import ProductsModalAddToCart from "@/modules/components/Products/ProductsModalAddToCart";
import DialogueModal from "@/modules/components/base/DialogueModal";
import ProductsModalAddToCartForSeveralProducts from "@/modules/components/Products/ProductsModalAddToCartForSeveralProducts";
import PersonalizedPopupTemplate1 from "@/modules/components/base/PersonalizedPopups/PersonalizedPopupTemplate1";
import PersonalizedPopupTemplate2 from "@/modules/components/base/PersonalizedPopups/PersonalizedPopupTemplate2";
import PersonalizedPopupForm from "@/modules/components/base/PersonalizedPopups/PersonalizedPopupForm";
import PersonalizedPopupThanks from "@/modules/components/base/PersonalizedPopups/PersonalizedPopupThanks";
import OrderDetailsModal from "@/modules/components/Account/OrderDetailsModal";
import OrderRetryModal from "@/modules/components/Account/OrderRetryModal";
import Dialog from "@/modules/components/base/Dialog";
import SuggestedProductsModal from "@/modules/components/Products/SuggestedProductsModal";
import StadaPopup from "@/modules/components/base/StadaPopup.vue";

export default defineComponent({
  name: "App",
  components: {
    StadaPopup,
    SuggestedProductsModal,
    Dialog,
    OrderRetryModal,
    OrderDetailsModal,
    PersonalizedPopupThanks,
    PersonalizedPopupForm,
    PersonalizedPopupTemplate2,
    PersonalizedPopupTemplate1,
    ProductsModalAddToCartForSeveralProducts,
    DialogueModal,
    ProductsModalAddToCart,
    Loader,
    Notification,
    CookiesPolicy,
  },

  setup() {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    return {
      scrollToTop,
    };
  },
});
</script>
