<template>
  <div class="favoriteIcon">
    <b-icon-heart
      v-if="isFavorite === false"
      @click="addProductToFavorite"
      height="2em"
    />
    <b-icon-heart-fill v-else @click="removeProductFromFavorite" height="2em" />
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "FavoriteIcon",

  props: {
    isFavorite: Boolean,
    productId: Number,
  },

  setup(props, context) {
    const store = useStore();

    const addProductToFavorite = () => {
      store.dispatch("ui/loader", true);
      store
        .dispatch("products/addProductToFavorite", { id: props.productId })
        .then(() => {
          context.emit("query");
        })
        .finally(() => {
          store.dispatch("ui/loader", false);
        });
    };

    const removeProductFromFavorite = () => {
      store.dispatch("ui/loader", true);
      store
        .dispatch("products/removeProductFromFavorite", {
          id: props.productId,
        })
        .then(() => {
          context.emit("query");
        })
        .finally(() => {
          store.dispatch("ui/loader", false);
        });
    };
    return {
      store,
      addProductToFavorite,
      removeProductFromFavorite,
    };
  },
};
</script>

<style scoped></style>
